import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Loading, Modal, Pagination, Tables } from "../../../components"
import Search from "../../../components/atoms/Search"
import AlertComp from "../../../components/layout/AlertComp"
import Card from "../../../components/layout/Card"
import { useMasterRouter } from "./routersFunction"
import { faEdit, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { Fragment } from "react"
import config from "../../../config"
import FormTextSingleComp from "../../../components/FormTextSingleComp"
import PropTypes from 'prop-types';

const RoutersPage = (props) => {
    const { onClickTr,
        hideButtonEdit,
        hideButtonDelete,
    } =  props;
    
    const { 
        handleSearch, routers, setRouters,
        alertValue, handleCloseAlert,
        paginateRouters, handleCreate, view,
        formData, setFormData, handleSubmit, handleCancel, 
        handleEdit, handleDelete, modalValue,
        setModalValue, handleSubmitDelete
    } = useMasterRouter();
    return(
        <div className='h-full w-full'>            
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                subText={alertValue.subText}
                icon={alertValue.icon}
            />
            <Modal
                show={modalValue.show}
                setShow={(params) => setModalValue(modalValue => ({...modalValue, show: params}))}
                width="sm"
            >
                <div className="text-lg font-normal">
					<div className="mb-3 border-b border-b-gray-300 p-5">Konfirmasi</div>
                    <div className="text-sm mb-3 p-5">
						{modalValue.text}
					</div>
					<div className="text-sm flex justify-end p-5 border-t border-t-gray-300">
                        <div className="flex flex-row">
                            <Button 
                                onClick={() => setModalValue(modalValue => ({...modalValue, show: false}))} 
                                color="primary"
                                className='rounded-l'
                                rounded="none"
                                size="sm"
                            >
                                Close
                            </Button>
                            <Button 
                                onClick={handleSubmitDelete} 
                                color="danger"
                                className='rounded-r'
                                rounded="none"
                                size="sm"
                            >
                                Hapus
                            </Button>
                        </div>
					</div>
                </div>
            </Modal>
            <Card 
                title="Master Router" 
                isSearch={false} 
                className="mt-5"
                isCreate={view === 'create'}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
            >
                { view === 'create' &&
                    <div className='grid grid-cols-2 gap-4'>
                        <div className='col-span-2 md:col-span-1'>
                            <FormTextSingleComp
                                {...formData.nama}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.host}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.uname}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.pwd}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.api_port}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                        </div>
                        <div className='col-span-2 md:col-span-1'>
                            <FormTextSingleComp
                                {...formData.ssh_port}
                                formData={formData}
                                setFormData={setFormData}
                            />                            
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.dns_name}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.deskripsi}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.os_ver}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                        </div>
                    </div>
                }
                { view === 'list' &&
                    <Fragment>
                        <div className="grid grid-cols-12 gap-4 mb-3">
                            <div className="col-span-6 w-full">                        
                                <Search
                                    className='mt-2'
                                    placeholder={"search"}
                                    onChange={(e) => setRouters(routers => ({...routers, search: e}))}
                                    handleSearch={handleSearch}
                                    value={routers.search}
                                    inputClassName={'py-2'}
                                />
                            </div>
                            <div className='col-span-6 flex justify-end'>
                                <Button
                                    color="primary"
                                    className='px-2'
                                    size="xs"
                                    onClick={handleCreate}
                                ><FontAwesomeIcon icon={faPlus} /></Button>
                            </div>
                        </div>
                        { routers.isLoading ?
                            <div>
                                <Loading variant={'skeleton'} loadingClassName='h-[30px] w-full' />
                                <div className="flex w-full gap-4">
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                </div>
                                <div className="flex w-full gap-4">
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                </div>
                            </div>
                            :
                            <Fragment>
                                <Tables>
                                    <Tables.Head className='bg-[#efeeee]'>
                                        <tr>
                                            <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Router Name</Tables.Header>
                                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Host</Tables.Header>
                                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Username</Tables.Header>
                                            <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>API Port</Tables.Header>
                                            <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>SSH Port</Tables.Header>
                                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Deskripsi</Tables.Header>                                    
                                            <Tables.Header className='font-bold text-[13px] py-[10px]'>DNS Name</Tables.Header>                                    
                                            <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>OS Ver</Tables.Header>
                                            { (!hideButtonDelete || !hideButtonEdit) &&
                                                <Tables.Header className='font-bold text-[13px] py-[10px] text-center'>Action</Tables.Header>
                                            }
                                        </tr>
                                    </Tables.Head>
                                    <Tables.Body>
                                        {routers && routers.listData && routers.listData.map((post, index) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data onClick={() => onClickTr ? onClickTr(post) : null} className={`text-right ${onClickTr && 'cursor-pointer'}`}>{routers.offset+index+1}</Tables.Data>                                                
                                                    <Tables.Data onClick={() => onClickTr ? onClickTr(post) : null} className={`${onClickTr && 'cursor-pointer'}`}>{post.nama}</Tables.Data>
                                                    <Tables.Data onClick={() => onClickTr ? onClickTr(post) : null} className={`${onClickTr && 'cursor-pointer'}`}>{post.host}</Tables.Data>
                                                    <Tables.Data onClick={() => onClickTr ? onClickTr(post) : null} className={`${onClickTr && 'cursor-pointer'}`}>{post.uname}</Tables.Data>
                                                    <Tables.Data onClick={() => onClickTr ? onClickTr(post) : null} className={`text-right ${onClickTr && 'cursor-pointer'}`}>{post.api_port}</Tables.Data>
                                                    <Tables.Data onClick={() => onClickTr ? onClickTr(post) : null} className={`text-right ${onClickTr && 'cursor-pointer'}`}>{post.ssh_port}</Tables.Data>
                                                    <Tables.Data onClick={() => onClickTr ? onClickTr(post) : null} className={`${onClickTr && 'cursor-pointer'}`}>{post.deskripsi}</Tables.Data>
                                                    <Tables.Data onClick={() => onClickTr ? onClickTr(post) : null} className={`${onClickTr && 'cursor-pointer'}`}>{post.dns_name}</Tables.Data>
                                                    <Tables.Data onClick={() => onClickTr ? onClickTr(post) : null} className={`text-right ${onClickTr && 'cursor-pointer'}`}>{post.os_ver}</Tables.Data>
                                                    { (!hideButtonDelete || !hideButtonEdit) &&
                                                        <Tables.Data className='text-center'>
                                                            <div className='flex flex-row'>
                                                                { !hideButtonEdit &&
                                                                    <button className='btn-edit bg-primary px-[5px] py-[2px] text-white' color='primary' onClick={() => handleEdit(post)}><FontAwesomeIcon icon={faEdit}/></button>
                                                                }
                                                                { !hideButtonDelete &&
                                                                    <button className='btn-edit bg-red-500 px-[5px] py-[2px] text-white' color='danger' onClick={() => handleDelete(post)}><FontAwesomeIcon icon={faTrashAlt}/></button>
                                                                }
                                                            </div>
                                                        </Tables.Data>
                                                    }
                                                </Tables.Row>
                                            )
                                        })}
                                    </Tables.Body>
                                </Tables>
                                <div className="flex justify-end">
                                    <Pagination
                                        totalCount={routers.totalRecords}
                                        onPageChange={(page) => {
                                            paginateRouters(page);
                                        }}
                                        currentPage={routers.page}
                                        pageSize={config.itemPerPage}
                                        className="mt-5 text-right"
                                    />
                                </div>
                            </Fragment>
                        }
                    </Fragment>
                }
            </Card>
        </div>
    )
}

RoutersPage.propTypes = {
    onClickTr : PropTypes.func,
    hideButtonEdit : PropTypes.bool,
    hideButtonDelete : PropTypes.bool,
}

RoutersPage.defaultProps = {
    // onClickTr : null,
    hideButtonEdit : false,
    hideButtonDelete : false,
}
export default RoutersPage;