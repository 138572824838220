import { Fragment } from "react";
import { usePaymentGateway } from "./paymentGatewayFunc";
import Card from "../../../components/layout/Card";
import AlertComp from "../../../components/layout/AlertComp";
import FormTextSingleComp from "../../../components/FormTextSingleComp";

import { Button,  Modal, Tables } from '../../../components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";




const PaymentGatewayPage = (props) => {
    const { 
        isLoading, alertValue, handleCloseAlert,
        modalValue, 
        // handleCloseModal,
        handleSubmitDelete,
        pageValue, handleSearch, 
        // handleKeyDownSearch, handleChangeSearch,
        handleCreate, listData,
        // handlePaginate, 
        handleEdit, handleDelete,
        // isEdit, 
        view, handleCancel, handleSubmit,
        formData, 
        setFormData, setModalValue, setPageValue
    } = usePaymentGateway();
    
    return(
        <Fragment>            
            
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}                
            />
            <Modal
                show={modalValue.show}
                setShow={(params) => setModalValue(modalValue => ({...modalValue, show: params}))}
                width="sm"
            >
                <div className="text-lg font-normal">
					<div className="mb-3 border-b border-b-gray-300 p-5">Konfirmasi</div>
                    <div className="text-sm mb-3 p-5">
						{modalValue.text}
					</div>
					<div className="text-sm flex justify-end p-5 border-t border-t-gray-300">
                        <div className="flex flex-row">
                            <Button 
                                onClick={() => setModalValue(modalValue => ({...modalValue, show: false}))} 
                                color="primary"
                                className='rounded-l'
                                rounded="none"
                                size="sm"
                            >
                                Close
                            </Button>
                            <Button 
                                onClick={handleSubmitDelete} 
                                color="danger"
                                className='rounded-r'
                                rounded="none"
                                size="sm"
                            >
                                Hapus
                            </Button>
                        </div>
					</div>
                </div>
            </Modal>
            <Card
                title="Payment Gateway"
                isSearch={true}
                isCreate={view === 'create'}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                onChangeSearch={(value) => setPageValue(pageValue => ({...pageValue, search : value}))}
                handleSearch={handleSearch}
                searchValue={pageValue.search}
                handleCreate={handleCreate}
                isLoading={isLoading}
                loadingHeight={30}
                loadingWidth={30}
            >
                { view === 'create' &&
                    <div className='grid grid-cols-12'>
                        <div className='col-start-0 md:col-start-4 col-span-12 md:col-span-6'>
                        {/* <div className='col-span-6'> */}
                            <FormTextSingleComp
                                {...formData.key}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <FormTextSingleComp
                                {...formData.nama}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <FormTextSingleComp
                                {...formData.icon}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <FormTextSingleComp
                                {...formData.tipe}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </div>
                    </div>
                }
                { view === 'list' &&
                    <Fragment>
                        <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee] rounded-full'>
                                <tr>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] rounded-full text-right'>#</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Payment Gateway</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Key</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Icon</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-center'>Tipe</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] rounded-r'></Tables.Header>                                
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                { listData.map((post, index) => {
                                    return(
                                        <Tables.Row key={index}>
                                            <Tables.Data className='text-right'>{pageValue.offset+index+1}</Tables.Data>                                                
                                            <Tables.Data>{'Midtrans'}</Tables.Data>
                                            <Tables.Data>{post.key_display}</Tables.Data>
                                            <Tables.Data>{post.nama}</Tables.Data>
                                            <Tables.Data  className='text-center'>
                                                <img src={post.icon} alt="icon" style={{width: 100}}/>
                                            </Tables.Data>                                                        
                                            <Tables.Data className='text-center'>
                                                <div>{post.tipe_display}</div>
                                            </Tables.Data>
                                            <Tables.Data>
                                                <div className='flex flex-row'>
                                                    <button className='btn-edit bg-primary px-[5px] py-[2px] text-white rounded-l' color='primary' onClick={() => handleEdit(post)}><FontAwesomeIcon icon={faEdit}/></button>
                                                    <button className='btn-edit bg-red-500 px-[5px] py-[2px] text-white rounded-r' color='danger' onClick={() => handleDelete(post)}><FontAwesomeIcon icon={faTrashAlt}/></button>
                                                </div>
                                            </Tables.Data>
                                        </Tables.Row>
                                    )
                                })}
                            </Tables.Body>
                        </Tables>
                    </Fragment>
                }
            </Card>
        </Fragment>
    )
}

export default PaymentGatewayPage;