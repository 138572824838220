/**
 *
 * @param {{
 * children: React.ReactNode;
 * size: "sm" | "md" | "lg" | "xl";
 * density: "tight" | "normal" | "loose";
 * tablefix: boolean;
 * height: integer;
 * }}
 *
 *
 */

import { useRef, useState } from "react";

const Tables = ({ children, size, density = "normal", tablefix, height, className }) => {
	// Size
	const tableSize =
		{
			sm: 12,
			md: 14,
			lg: 16,
			xl: 18,
		}[size] || 14;

	// Density
	const tableDensity =
		{
			tight: "[&>thead>tr>th]:py-1 [&>tbody>tr>td]:py-1",
			normal: "[&>thead>tr>th]:py-2 [&>tbody>tr>td]:py-2",
			loose: "[&>thead>tr>th]:py-3 [&>tbody>tr>td]:py-3",
		}[density] || "[&>thead>tr>th]:py-2 [&>tbody>tr>td]:py-2";

	return (
		<div
			style={{
				height: `${height}px`,
			}}
			className="w-full overflow-auto custom-scroll"
		>
			<table
				style={{
					fontSize: `${tableSize}px`,
				}}
				className={`w-full border-separate border-spacing-0 ${tableDensity} ${
					tablefix ? "table-fixed" : "table-auto"
				} ${className}`}
			>
				{children}
			</table>
		</div>
	);
};

const TableHead = ({ children, style, sticky, className }) => {
	return (
		<thead
			style={{
				...style,
			}}
			className={`${
				sticky ? "sticky top-0 bg-white dark:bg-base-600 shadow-sm" : ""
			} ${className}`}
		>
			{children}
		</thead>
	);
};

const TableBody = ({ children, style }) => {
	return (
		<tbody
			style={{
				...style,
			}}
		>
			{children}
		</tbody>
	);
};

const TableRow = ({ children, style, expandable, className }) => {
	const ref = useRef(null);
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<>
			<tr
				style={{
					...style,
				}}
				onClick={() => setIsExpanded(!isExpanded)}
				className={`${expandable ? "cursor-pointer" : ""} ${className}`}
			>
				{children}
			</tr>

			{expandable && (
				<tr>
					<td
						style={{
							padding: "0px",
						}}
						colSpan={children.length}
					>
						<div
							ref={ref}
							style={{
								height: isExpanded ? `${ref.current.scrollHeight}px` : "0px",
							}}
							className={`overflow-hidden transition-[height] duration-300 ease-in-out ${
								isExpanded ? "border-b dark:border-base-500" : ""
							}`}
						>
							{expandable}
						</div>
					</td>
				</tr>
			)}
		</>
	);
};

const TableHeader = ({ children, style, center, className }) => {
	return (
		<th
			style={{
				...style,
			}}
			className={`border-b dark:border-base-500 ${ className && !className.includes('font') && 'font-normal' } uppercase tracking-wide px-2 sticky ${
				center ? "text-center" : "text-left"
			} ${className}`}
		>
			{children}
		</th>
	);
};

const TableData = ({ children, style, center, colspan, className, onClick }) => {
	return (
		<td
			style={{
				...style,
			}}
			colSpan={colspan}
			className={`border-b dark:border-base-500 px-2 ${
				center ? "text-center" : "text-left"
			} ${className}`}
			onClick={onClick}
		>
			{children}
		</td>
	);
};

Tables.Head = TableHead;
Tables.Body = TableBody;
Tables.Row = TableRow;
Tables.Header = TableHeader;
Tables.Data = TableData;

export default Tables;
