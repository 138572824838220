import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { TbSearch } from "react-icons/tb";

const Search = (props) => {
    const { placeholder,
        onChange,
        handleSearch,
        value,
        inputClassName,
        className
    } = props;
    return(
        <Fragment>
            <div className={`relative  w-full flex justify-end ${className}`}>
                <input 
                    className={`border border-gray-300 rounded py-1 px-2 w-full ${inputClassName}`}
                    placeholder={placeholder} 
                    onChange={(e) => onChange(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter'){
                            handleSearch()
                        }
                    }}
                    value={value}                    
                />
                <button className="absolute bottom-[0px] right-[20px] h-full" onClick={handleSearch}>
                    <div className='flex flex-row items-center'>
                        <TbSearch className='text-xl'/>
                    </div>
                </button>
            </div>
        </Fragment>
    )
}

Search.propTypes = {
    placeholder : PropTypes.string,
    onChange: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    inputClassName: PropTypes.string,
    className: PropTypes.string,
}

export default Search