import { Fragment } from "react"
import { useSetting } from "./settingFunction"
import AlertComp from "../../../components/layout/AlertComp";
import Card from "../../../components/layout/Card";
import FormTextSingleComp from "../../../components/FormTextSingleComp";


const SettingPage = (props) => {
    const { 
        alertValue,   handleCloseAlert, 
        // pageValue, setPageValue, 
        // modalValue, 
        // setModalValue, 
        // handleCloseModal, 
        // isLoading, setIsLoading, 
        // bgColor, 
        listData, onChange, onBlur,
        handleSubmit
    } = useSetting();

    console.log("listData >>>>>", listData)
    return (
        <Fragment>
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                subText={alertValue.subText}
                icon={alertValue.icon}
            />
            <Card
                title={"Setting"}
                isSearch={false}
                isCreate={true}
                handleSubmit={handleSubmit}
                handleCancel={handleSubmit}
            >
                <div className="grid grid-cols-2">
                    <div className="col-span-2 md:col-span-1">
                        { listData.map((post, index) => {
                            return(
                                <div key={index} className='w-full'>
                                    <FormTextSingleComp
                                        name={post.name_field}
                                        value={post.value}
                                        type="text"
                                        label={post.deskripsi}
                                        onChange={(e) => onChange(e, index)}
                                        onBlur={(e) => onBlur(e, index)}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Card>
        </Fragment>
    )
}

export default SettingPage;