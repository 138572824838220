import { useCallback, useState } from "react";
import api from "../../api";
import config from "../../config";
import { pageObj } from "../../generalFunction/pageObj";



export function useCampaignApproveLogs(){
    const [campaignApproveLogs, setCampaignApproveLogs] = useState({
        pageObj : [],
        listData : [],
        lastPage : 0,
        offset : 0,
        page : 1,
        totalRecords : 0,
        search : '',
        isLoading : true,
        isError : false,
        errorMsg : '',
        errorStatus : null,
    })

    const fetchCampaignApproveLogs = useCallback(async(q='', offset=0, limit=config.itemPerPage, variable=null) => {        
        setCampaignApproveLogs(campaignApproveLogs => ({...campaignApproveLogs, isLoading: true, listData : []}))        
        try{
            let params = {
                offset : offset,
                limit : limit
            };
            if (q){
                params['q'] = q
            }
            if (variable){
                params = {
                    ...params,
                    ...variable
                }                
            }
            const res = await api.get(`${config.endPoint.campaignApproveLog}`, { params : params}).then(res => res.data);
            if (res){
                setTimeout(() => {
                    setCampaignApproveLogs(campaignApproveLogs => ({...campaignApproveLogs,
                        pageObj : pageObj(res.count, limit, offset),
                        listData : res.results,
                        lastPage : Math.ceil(parseInt(res.count) / parseInt(limit)),
                        totalRecords : res.count,
                        isLoading : false
                    }))
                }, 500)
                return res
            }
        } catch (error) {
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;
            let errorStatus = null
            if (response && response.data && response.data.message){
                msg = response.data.message
            }         
            if (response && response.status){
                errorStatus = response.status
            }   
            
            setCampaignApproveLogs(campaignApproveLogs => ({...campaignApproveLogs, isError : true, errorMsg : msg, isLoading : false, errorStatus: errorStatus, lastPage: 0, pageObj : []}))
            return error
        }
    },[])

    const paginateCampaignApproveLogs = (page) => {
        
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setCampaignApproveLogs(campaignApproveLogs => ({...campaignApproveLogs,
            page : page, 
            offset: myOffset        
        }))
        
        fetchCampaignApproveLogs(campaignApproveLogs.search, myOffset, config.itemPerPage);        
    }

    return {
        campaignApproveLogs, setCampaignApproveLogs, fetchCampaignApproveLogs, paginateCampaignApproveLogs
    }
}