import { useContext, useEffect } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import { DataContext } from "../../../context/DataContext";
import config from "../../../config";

export function useIklanTayang(props){
    const {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, 
    } = useGlobalFunction();
    const { 
        campaignLogs, fetchCampaignLogs, setCampaignLogs
    } = useContext(DataContext)

    useEffect(() => {
        fetchCampaignLogs({});
    },[fetchCampaignLogs])



    const paginateCampaignLogs = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setCampaignLogs(campaignLogs => ({...campaignLogs,
            page : page, 
            offset: myOffset        
        }))
        
        fetchCampaignLogs({
            q: campaignLogs.search, 
            ofsset: myOffset, 
            config: config.itemPerPage
        });
    }
    

    
    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, campaignLogs, paginateCampaignLogs
    }
}