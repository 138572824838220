import PropTypes from 'prop-types';
import { Label } from '../../../components/forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBatteryFull, faSignal, faWifi } from '@fortawesome/free-solid-svg-icons';

const RestoForm = (props) => {
    const { readOnly, data, handleCancel, handleApprove, isLoading, butonText } = props;
    
    console.log('data >>>>', data ? data : 'ga adaa')
    return(
        <div className='p-8'>
            { readOnly &&
                <div className='text-sm text-gray-900 grid grid-cols-2 gap-3 h-[800px]'>
                    <div className="col-span-1">
                        <div className='grid grid-cols-12 gap-3'>                    
                            <div className='col-span-3'>Nama Cafe</div>
                            <div className='col-span-9'>: { data && data.nama}</div>
                        </div>
                        <div className='grid grid-cols-12 gap-3'>                    
                            <div className='col-span-3'>Owner</div>
                            <div className='col-span-9'>: { data && data.owner_name}</div>
                        </div>
                        <div className="flex flex-row">
                            <button className='px-5 py-2 text-white bg-blue-600 mt-5' onClick={handleApprove} disabled={isLoading}>
                                { isLoading &&
                                    <div
                                        className="mr-5 inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                        role="status">
                                        <span
                                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                            >Loading...</span>
                                    </div>
                                }
                                { butonText ? butonText : 'Approve' }
                            </button>
                            <button className='px-5 py-2 text-white bg-gray-600 mt-5' onClick={handleCancel} disabled={isLoading}>
                                { isLoading &&
                                    <div
                                        className="mr-5 inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                        role="status">
                                        <span
                                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                            >Loading...</span>
                                    </div>
                                }
                                Cancel
                            </button>
                        </div>
                    </div>
                    <div className='col-span-1'>
                        <div className={`h-full`}>
                            <div 
                                className='flex h-[25px] bg-slate-700 flex-row items-center justify-between px-2'
                            >
                                <div className='text-[12px] text-white'>14:32</div>
                                <div className='flex flex-row justify-end text-[12px] text-white gap-1'>
                                    <FontAwesomeIcon icon={faSignal}/>
                                    <FontAwesomeIcon icon={faWifi}/>
                                    <FontAwesomeIcon icon={faBatteryFull}/>
                                </div>
                            </div>
                            <div className='h-full flex flex-col justify-center items-center' style={{background: data && data.template ? data.template.background : '#fff'}}>
                                <div className='flex items-center justify-center w-full   px-24'>
                                    <img 
                                        src={data  && data.logo}
                                        alt="icon"
                                    />                                
                                </div>
                                <div 
                                    className='text-center mt-5' 
                                    style={{
                                        color: data && data.template && data.template.welcome_text ? data.template.welcome_text.color : '#000',
                                        fontWeight : data && data.template && data.template.welcome_text ? data.template.welcome_text.fontWeight : 'normal',
                                        fontStyle : data && data.template && data.template.welcome_text ? data.template.welcome_text.fontStyle : 'normal',                                        
                                        fontSize : data && data.template && data.template.welcome_text ? `${data.template.welcome_text.fontSize}px` : '14px',
                                    }}
                                >{data && data.template && data.template.welcome_text && data.template.welcome_text.text}</div>
                                { data && data.template && data.template.tag_line &&
                                    <div 
                                        className='text-center' 
                                        style={{
                                            color: data && data.template && data.template.tag_line ? data.template.tag_line.color : '#000',
                                            fontWeight : data && data.template && data.template.tag_line ? data.template.tag_line.fontWeight : 'normal',
                                            fontStyle : data && data.template && data.template.tag_line ? data.template.tag_line.fontStyle : 'normal',                                        
                                            fontSize : data && data.template && data.template.tag_line ? `${data.template.tag_line.fontSize}px` : '14px',
                                        }}
                                    >{data && data.template && data.template.tag_line && data.template.tag_line.text}</div>
                                }
                                <div className='px-16 w-full mt-7'>
                                    <button 
                                        className='py-3 w-full rounded-full'
                                        style={{
                                            background: data && data.template && data.template.button_in_text && data.template.button_in_text.background ? data.template.button_in_text.background : '#000',
                                            color: data && data.template && data.template.button_in_text ? data.template.button_in_text.color : '#000',
                                            fontWeight : data && data.template && data.template.button_in_text ? data.template.button_in_text.fontWeight : 'normal',
                                            fontStyle : data && data.template && data.template.button_in_text ? data.template.button_in_text.fontStyle : 'normal',                                        
                                            fontSize : data && data.template && data.template.button_in_text ? `${data.template.button_in_text.fontSize}px` : '14px',
                                        }}
                                    >
                                        {data && data.template && data.template.button_in_text && data.template.button_in_text.text}
                                    </button>
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
            }
        </div>
    )
}

RestoForm.propTypes = {
    readOnly : PropTypes.bool, 
    isLoading : PropTypes.bool, 
    data : PropTypes.object,
    handleCancel : PropTypes.func,
}

RestoForm.defaultProps = {
    readOnly : false,
    isLoading : false,
}
export default RestoForm;