import { useGlobalFunction } from "../../generalFunction/generalFunction";
import { useContext, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { loadSlim } from "tsparticles-slim";
import { useCookies } from 'react-cookie';
import { ThemeContext } from "../../context/ThemeContext";
import api from "../../api";
import config from "../../config";

export function useLogin(props){
    const { 
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading,
    } = useGlobalFunction();
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies([config.cookiesName]);
    const { colorMode, themeColor } = useContext(ThemeContext);
    const [formData, setFormData] = useState({
		username: {
            name: 'username',
            tipe: 'text',
            value: '',
            label: 'Username',
            required: true,
            showError: false,
        },
        password: {
            name: 'password',
            tipe: 'password',
            value: '',
            label: 'Password',
            required: true,
            showError: false,
            isShow: false,
			inputType: 'password'
        }
	})

    const particlesInit = useCallback(async (engine) => {
		await loadSlim(engine);
	}, []);

	const optionparticles = {
		fullScreen: {
			enable: false,
			zIndex: 1,
		},
		fpsLimit: 120,
		interactivity: {
			events: {
				onHover: {
					enable: true,
					mode: "repulse",
				},
				resize: true,
			},
			modes: {
				repulse: {
					distance: 100,
					duration: 0.4,
				},
			},
		},
		particles: {
			color: {
				value: colorMode === "dark" ? "#fff" : themeColor,
			},
			links: {
				color: colorMode === "dark" ? "#fff" : themeColor,
				distance: 100,
				enable: true,
				opacity: 0.5,
				width: 1,
			},
			move: {
				direction: "none",
				enable: true,
				outModes: {
					default: "bounce",
				},
				random: false,
				speed: 2,
				straight: false,
			},
			number: {
				density: {
					enable: true,
					area: 500,
				},
				value: 80,
			},
			opacity: {
				value: 0.5,
			},
			shape: {
				type: "circle",
			},
			size: {
				value: { min: 1, max: 2 },
			},
		},
	};

    const handleSubmit = async() => {
		setIsLoading(true);
		console.log('click submit')
		// try{
			let obj = {...formData}
			let tmp = {}
			let countError = 0;

			Object.entries(obj).map(([index, post]) => {
				if (post.required && post.value === ''){
					post.showError = true;
					countError++;
					console.log('index post >>>>>>', index)
				}else{
					post.showError = false;
					tmp[index] = post.value
				}
				return true;
			})

			let newForm = new FormData();
			newForm.append('formData', JSON.stringify(tmp))

			const res = await api.post(`${config.endPoint.login}`, newForm).then(res => res.data);
			if (res){                
                let tmpcookie = {
                    'token' : res.token, 
                    'user_info' : res.nama,
                    'is_superuser' : res.is_superuser,                    
                    'user_perm' : res.user_perm,
                };                                
			    setCookie(config.cookiesName, tmpcookie, { path: '/', maxAge: config.expiresSession });                
                return window.location.href = '/'
            }            
            setIsLoading(false);
			// console.log('res is >>>>>', res);

			if (countError > 0){
				setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi data', color : 'danger'}))
				setTimeout(() => {
					setAlertValue(alertValue => ({...alertValue, show: false}))
				}, config.timeOutValue)

			}
		// }catch(error){

		// }
	}

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, 
        formData, setFormData, handleSubmit, optionparticles, particlesInit, navigate, themeColor,
        cookies
    }
}