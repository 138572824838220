import { ThemeContext } from "../../context/ThemeContext";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { useContext } from "react";
import { FiChevronRight } from "react-icons/fi";

const Collapsible = ({ children, multiple, initialexpanded }) => {
	return (
		<Accordion
			allowMultiple={multiple}
			initialEntered={initialexpanded}
			className="w-full"
			transition
			transitionTimeout={300}
		>
			{children}
		</Accordion>
	);
};

const CollapsibleItem = ({
	header,
	children,
	icon,
	initialexpanded,
	disabled,
}) => {
	const { themeSkin } = useContext(ThemeContext);
	return (
		<AccordionItem
			disabled={disabled}
			initialEntered={initialexpanded}
			className={`text-sm mb-2 rounded-md  ${
				themeSkin === "default"
					? "shadow-[0_2px_10px_rgb(0,0,0,0.1)] bg-white dark:bg-base-600"
					: themeSkin
			}`}
			buttonProps={{
				className: "w-full text-left px-4 py-3 min-h-[42px]",
			}}
			contentProps={{
				className: "transition-all ease-in-out duration-300",
			}}
			header={({ state: { isEnter } }) => (
				<div className="flex items-center justify-between w-full">
					{header}
					{icon ? (
						icon
					) : (
						<FiChevronRight
							className={`transition-transform duration-300 ${
								isEnter ? "rotate-90" : ""
							}`}
						/>
					)}
				</div>
			)}
		>
			{typeof children === "string" ? (
				<div className="px-4 pb-3 text-sm">{children}</div>
			) : (
				children
			)}
		</AccordionItem>
	);
};

Collapsible.Item = CollapsibleItem;

export default Collapsible;
