import { Fragment } from "react";
import AlertComp from "../../../components/layout/AlertComp";
import { useResto } from "./restoFunction";
import Search from "../../../components/atoms/Search";
import { Loading, Modal, Pagination, Tables, Tooltip } from "../../../components";
import Card from "../../../components/layout/Card";
import config from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCogs, faTimes } from "@fortawesome/free-solid-svg-icons";

const RestoPage = (props) => {
    const { resto, isLoading, alertValue, view, handleSearch, setResto,
        handleCloseAlert, paginateResto, handleShowRouter,
        modalState, closeModalHandler, bgStatusResto, handleAktif,
    } = useResto();
    
    return(
        <div className='h-full w-full'>
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                subText={alertValue.subText}
                icon={alertValue.icon}
            />
            <Modal
                show={modalState.isOpen}
                // setShow={(params) => setModalValue(modalValue => ({...modalValue, show: params}))}
                setShow={closeModalHandler}
                width="xl"
            >
                <div className="text-lg font-normal">
                    {modalState.content}
                </div>
            </Modal>            
            <Card 
                title="Cafe" 
                isSearch={false} 
                className="mt-5"
                isCreate={false}
                isLoading={isLoading}
            >
                { view === 'list' &&
                    <Fragment>
                        <div className="grid grid-cols-12 gap-4 mb-3">
                            <div className="col-span-6 w-full">                        
                                <Search
                                    className='mt-2'
                                    placeholder={"search"}
                                    onChange={(e) => setResto(resto => ({...resto, search: e}))}
                                    handleSearch={handleSearch}
                                    value={resto.search}
                                    inputClassName={'py-2'}                                    
                                />
                            </div>                            
                        </div>
                        { resto.isLoading ?
                            <div>
                                <Loading variant={'skeleton'} loadingClassName='h-[30px] w-full' />
                                <div className="flex w-full gap-4">
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                </div>
                                <div className="flex w-full gap-4">
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                </div>
                            </div>
                            :
                            <Fragment>
                                <Tables>
                                    <Tables.Head className='bg-[#efeeee]'>
                                        <tr>
                                            <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Resto Name</Tables.Header>
                                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Owner</Tables.Header>
                                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Router</Tables.Header>
                                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Status</Tables.Header>
                                            <Tables.Header className='font-bold text-[13px] py-[10px] text-center'>Action</Tables.Header>
                                        </tr>
                                    </Tables.Head>
                                    <Tables.Body>
                                        {resto && resto.listData && resto.listData.map((post, index) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data className='text-right'>{resto.offset+index+1}</Tables.Data>
                                                    <Tables.Data>{post.nama}</Tables.Data>
                                                    <Tables.Data>{post.owner_name}</Tables.Data>
                                                    <Tables.Data>
                                                        {post.router_display ? post.router_display.router_name : 'Belum ada router'}
                                                        { !post.router_display &&
                                                            <button className='bg-green-600 ml-2 text-white px-2 py-1 rounded' onClick={() => handleShowRouter(post)}>
                                                                <FontAwesomeIcon icon={faCogs} />
                                                            </button>
                                                        }
                                                    </Tables.Data>
                                                    <Tables.Data>
                                                        <div className={`${bgStatusResto(post.state)} px-4 py-1 w-fit text-sm rounded-full`}>
                                                            {post.state_display}
                                                        </div>
                                                    </Tables.Data>
                                                    <Tables.Data className='text-center'>
                                                        { [1,2,4].includes(post.state) &&
                                                            <Tooltip tooltip="Aktifkan">
                                                                <button 
                                                                    className='text-white bg-green-500 px-2 py-1 rounded'
                                                                    onClick={() => handleAktif(post)}
                                                                >
                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                </button>
                                                            </Tooltip>
                                                        }
                                                        { post.state === 3 &&
                                                            <Tooltip tooltip="Terminate">
                                                                <button 
                                                                    className='text-white bg-red-500 px-2 py-1 rounded'
                                                                    onClick={() => handleAktif(post)}
                                                                >
                                                                    <FontAwesomeIcon icon={faTimes} />
                                                                </button>
                                                            </Tooltip>
                                                        }
                                                    </Tables.Data>
                                                </Tables.Row>
                                            )
                                        })}
                                    </Tables.Body>
                                </Tables>
                                <div className="flex justify-end">
                                    <Pagination
                                        totalCount={resto.totalRecords}
                                        onPageChange={(page) => {
                                            paginateResto(page);
                                        }}
                                        currentPage={resto.page}
                                        pageSize={config.itemPerPage}
                                        className="mt-5 text-right"
                                    />
                                </div>
                            </Fragment>
                        }
                    </Fragment>
                }
            </Card>
        </div>
    )
}
export default RestoPage;