import { Fragment, useContext } from "react"
import { useIklan } from "./iklanFunction"
import { Button, ButtonRipple, Loading, Modal, Tables, Tooltip } from "../../../components";
import Card from "../../../components/layout/Card";
import { ThemeContext } from "../../../context/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import MapComp from "../../../components/MapComp";
import { Marker, Circle } from 'google-maps-react';
import FormTextSingleComp from "../../../components/FormTextSingleComp";
import AlertComp from "../../../components/layout/AlertComp";
import {  faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Player } from "video-react";
import { Label } from "../../../components/forms";


const IklanPage = (props) => {
    const { themeColor } = useContext(ThemeContext)
    const { getTitle, view, totalWaiting, totalApproved, handleChangeView, listData,
        setCampaigns, campaigns, handleSearch, handleShowApprove, totalAll,
        selectedCampaign, handleCancel, initLang, handleApprove,
        modalValue,  setModalValue, handleSubmitApprove,
        handleChangeAlasan, handleBlurAlasan, alertValue, handleCloseAlert,
        // setAlertValue,

        handleClickMaps, formData, setFormData, showMarker,
        handleDeleteLocation, handleCreate, 
        handleSubmit, 
        handleChangeVideo, handleChangePilihan,
        handleBlurPilihan, handleAddPertanyaan, handleDeleteJawaban, videoSrc, formPertanyaan, setFormPertanyaan,
        locations, 
    } = useIklan();

    const approveColor = (params) => {
        if (params === 1){
            return 'bg-blue-100 text-blue-700'
        }else if (params === 3){
            return 'bg-red-100 text-red-700'
        }else{
            return 'bg-green-100 text-green-700'

        }
    }


    return (
        <Fragment>
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                subText={alertValue.subText}
                icon={alertValue.icon}
            />
            {/* <Alert
                color="primary"
                rounded="sm"
                variant="solid"
                show={alertValue.show}
                setShow={(value) => setAlertValue(alertValue => ({...alertValue, show: value}))}
                closable={true}
                density="tight"
            >
            </Alert> */}
            <Modal
                show={modalValue.show}
                width="lg"
                setShow={(value) => setModalValue(modalValue => ({...modalValue, show: value}))}
                btnClose={true}
            >
                <div className="text-lg font-normal">
					<div className="mb-3 border-b border-b-gray-300 p-5">Konfirmasi</div>
                    <div className="text-sm mb-3 p-5">
						{modalValue.text}
					</div>
                    { modalValue.tipe === 'reject' &&
                        <div className='px-5 mb-5'>
                            <FormTextSingleComp
                                name='params'
                                type={"text"}
                                onChange={handleChangeAlasan}
                                onBlur={handleBlurAlasan}
                                showError={modalValue.isError}
                                value={modalValue.params}
                                label="Alasan"                            
                            />
                        </div>
                    }

                    <div className="text-sm flex justify-end p-5 border-t border-t-gray-300">
                        <div className="flex flex-row">
                            <Button 
                                onClick={() => setModalValue(modalValue => ({...modalValue, show: false}))} 
                                color="base"
                                className='rounded-l font-normal py-[10px] px-[15px]'
                                rounded="none"
                                size="sm"
                            >
                                Close
                            </Button>
                            <Button 
                                onClick={handleSubmitApprove} 
                                color={modalValue.tipe === 'approved' ? 'success' : "danger"}
                                className='rounded-r font-normal py-[10px] px-[15px]'
                                rounded="none"
                                size="sm"
                            >
                               {modalValue.tipe === 'approved' ? 'Approve' : 'Reject'}
                            </Button>
                        </div>
					</div>
                </div>
            </Modal>
            { ['create'].includes(view) &&
                <div className="grid grid-cols-12 gap-6 overflow-hidden">
                    <div className="md:col-span-6 col-span-2">
                        <Card 
                            title="Buat Iklan"
                            isCreate={true}
                            isSearch={false}
                            handleSubmit={handleSubmit}
                            handleCancel={handleCancel}
                        >
                            <FormTextSingleComp
                                {...formData.nama}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <FormTextSingleComp
                                {...formData.tipe}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <div className='grid grid-cols-2 gap-4'>
                                <div className="col-span-1 w-full">
                                    <FormTextSingleComp
                                        {...formData.start_date}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <FormTextSingleComp
                                        {...formData.end_date}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="col-span-1 w-full">
                                    <FormTextSingleComp
                                        {...formData.start_time}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <FormTextSingleComp
                                        {...formData.end_time}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                            </div>
                            {[1, '1'].includes(formData.tipe.value) &&
                                <>
                                    <FormTextSingleComp
                                        {...formData.file_media}
                                        formData={formData}
                                        setFormData={setFormData}
                                        handleChangeFile={handleChangeVideo}
                                    />
                                    {videoSrc !== '' &&
                                        <Player
                                            playsInline
                                            src={videoSrc}
                                            fluid={false}
                                            width={480}
                                            height={272}
                                        />
                                    }
                                </>
                            }
                            {[2, '2'].includes(formData.tipe.value) &&
                                <Fragment>
                                    <div className='border-t border-t-gray-300 mt-[15px] pt-[15px]'>
                                        <div className="font-bold">Buat Pertanyaan</div>
                                        <div>
                                            <FormTextSingleComp
                                                {...formPertanyaan.tipe}
                                                formData={formPertanyaan}
                                                setFormData={setFormPertanyaan}
                                            />
                                            <FormTextSingleComp
                                                {...formPertanyaan.pertanyaan}
                                                formData={formPertanyaan}
                                                setFormData={setFormPertanyaan}
                                            />

                                            {[1, '1'].includes(formPertanyaan.tipe.value) &&
                                                <Fragment>
                                                    {formPertanyaan.pilihan_obj.map((post, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <Label>{`Pilihan ${index + 1}`}</Label>
                                                                <div className="flex flex-row items-end">
                                                                    <div className='w-[30%]'>
                                                                        <Label>Key</Label>
                                                                        <div>
                                                                            <FormTextSingleComp
                                                                                name='key'
                                                                                type='text'
                                                                                onChange={(e) => handleChangePilihan(e, index)}
                                                                                onBlur={(e) => handleBlurPilihan(e, index)}
                                                                                value={post.key}
                                                                                show={true}
                                                                                showError={post.showError}
                                                                                hideLabel={true}
                                                                            // hideLabel={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='ml-[5px] flex-1'>
                                                                        <Label>Jawaban</Label>
                                                                        <FormTextSingleComp
                                                                            name='value'
                                                                            label={`Pilihan ${index + 1}`}
                                                                            type='text'
                                                                            onChange={(e) => handleChangePilihan(e, index)}
                                                                            onBlur={(e) => handleBlurPilihan(e, index)}
                                                                            value={post.value}
                                                                            show={true}
                                                                            showError={post.showError}
                                                                            hideLabel={true}
                                                                        // hideLabel={true}
                                                                        />
                                                                    </div>
                                                                    {index > 1 &&
                                                                        <div className='mt-[2px] mb-2 ml-[2px]'>
                                                                            <button className='bg-red-500 text-white py-[10px] px-[10px] rounded' onClick={() => handleDeleteJawaban(index)}><FontAwesomeIcon icon={faTimes} /></button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    <button className='px-5 py-2 text-sm bg-primary text-white' onClick={handleAddPertanyaan}>Tambah Pilihan Jawaban</button>
                                                </Fragment>
                                            }
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </Card>
                    </div>
                    <div className="md:col-span-6 col-span-2 bg-white p-[16px] flex flex-col pl-[25px] dark:bg-base-600">
                        <div className="col-span-1 w-full">
                            <div className="grid grid-cols-12">
                                <div className='col-span-4'>
                                    <Label>{formData.all_location.label}</Label>
                                </div>
                                <div className='col-span-8'>
                                    <FormTextSingleComp
                                        {...formData.all_location}
                                        formData={formData}
                                        setFormData={setFormData}
                                        hideLabel={true}
                                    />
                                </div>
                            </div>
                        </div>    
                        { !formData.all_location.value &&
                            <>
                                <div className='font-bold mb-[10px]'>Masukan Lokasi Iklan</div>
                                <div className="relative">
                                    <MapComp
                                        google={props.google}
                                        zoom={15}
                                        init_lat={initLang.lat}
                                        init_lng={initLang.lng}
                                        // style={style}
                                        onClick={handleClickMaps}
                                        showMarker={showMarker}
                                        containerStyle={{ position: "relative", height: "40vh" }}
                                    // lat={latLon.lat}
                                    // lng={latLon.lon}
                                    // onDragend={handleDragged}
                                    >
                                        {locations.map((post, index) => {
                                            return (
                                                <Circle
                                                    key={index}
                                                    center={{ lat: post.lat, lng: post.lng }}
                                                    radius={500}
                                                    // strokeColor="#d40020"
                                                    strokeColor="#FF0000"
                                                    strokeOpacity={0.8}
                                                    strokeWeight={2}
                                                    fillColor="#FF0000"
                                                    fillOpacity={0.35}
                                                >
                                                    <Marker
                                                        key={index}
                                                        position={{
                                                            lat: post.lat,
                                                            lng: post.lng
                                                        }}
                                                    />
                                                </Circle>
                                            )
                                        })}
                                    </MapComp>
                                </div>
                                <div className='mt-[20px]'>
                                    <table className='w-full'>
                                        <thead className='bg-gray-50 text-[14px]'>
                                            <tr className=''>
                                                <th className='py-[10px] text-left px-[15px]'>#</th>
                                                <th className='py-[10px] text-left px-[15px]'>Latitude</th>
                                                <th className='py-[10px] text-left px-[15px]'>Longitude</th>
                                                <th className='py-[10px] text-left px-[15px]'>Hapus</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {locations.map((post, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className='py-[10px] text-left px-[15px]'>{index + 1}</td>
                                                        <td className='py-[10px] text-left px-[15px]'>{post.lat}</td>
                                                        <td className='py-[10px] text-left px-[15px]'>{post.lng}</td>
                                                        <td className='py-[10px] text-left px-[15px]'>
                                                            <button onClick={() => handleDeleteLocation(index)} className='px-[5px] py-[2px] bg-red-500 text-white text-[12px] rounded'>
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }
                    </div>
                </div>
            }
            {['waiting_approval', 'approved', 'all', 'reject'].includes(view) &&
                <>
                    <div className="flex gap-2">
                        <Button
                            type="button"
                            variant="solid"
                            textcolor="white"
                            color={view === 'all' ? "primary" : "base"}
                            className="flex flex-row gap-2 items-center"
                            onClick={() => handleChangeView('all')}
                        >
                            <div>All</div>
                            {totalAll.isLoading ?
                                <Loading variant={"falling_lines"} width={25} color={"#fff"} />
                                :
                                <div className={`w-[20px] h-[20px] rounded-full bg-white text-[12px]`} style={{ color: view === 'all' ? themeColor : '#BABCBD' }}>
                                    {totalAll.count}
                                </div>
                            }
                        </Button>
                        <Button
                            type="button"
                            variant="solid"
                            textcolor="white"
                            color={view === 'waiting_approval' ? "primary" : "base"}
                            className="flex flex-row gap-2 items-center"
                            onClick={() => handleChangeView('waiting_approval')}
                        >
                            <div>Waiting Approval</div>
                            {totalWaiting.isLoading ?
                                <Loading variant={"falling_lines"} width={25} color={"#fff"} />
                                :
                                <div className={`w-[20px] h-[20px] rounded-full bg-white text-[12px]`} style={{ color: view === 'waiting_approval' ? themeColor : '#BABCBD' }}>
                                    {totalWaiting.count}
                                </div>
                            }
                        </Button>
                        <Button
                            type="button"
                            variant="solid"
                            textcolor="white"
                            color={view === 'approved' ? "primary" : "base"}
                            className="flex flex-row gap-2 items-center"
                            onClick={() => handleChangeView('approved')}
                        >
                            <div>Approved</div>
                            {totalApproved.isLoading ?
                                <Loading variant={"falling_lines"} width={25} color={"#fff"} />
                                :
                                <div className={`w-[20px] h-[20px] rounded-full bg-white text-[12px]`} style={{ color: view === 'approved' ? themeColor : '#BABCBD' }}>
                                    {totalApproved.count}
                                </div>
                            }
                        </Button>
                        <Button
                            type="button"
                            variant="solid"
                            textcolor="white"
                            color={view === 'reject' ? "primary" : "base"}
                            className="flex flex-row gap-2 items-center"
                            onClick={() => handleChangeView('reject')}
                        >
                            <div>Reject</div>
                            {totalApproved.isLoading ?
                                <Loading variant={"falling_lines"} width={25} color={"#fff"} />
                                :
                                <div className={`w-[20px] h-[20px] rounded-full bg-white text-[12px]`} style={{ color: view === 'reject' ? themeColor : '#BABCBD' }}>
                                    {totalApproved.count}
                                </div>
                            }
                        </Button>
                        <Button
                            type="button"
                            variant="solid"
                            textcolor="white"
                            color={"primary"}
                            className="flex flex-row gap-2 items-center"
                            onClick={handleCreate}
                        >
                            <div>Create</div>
                            <div className='text-[20px]'>
                                <FontAwesomeIcon icon={faPlusCircle}/>
                            </div>
                        </Button>
                    </div>
                    <div className='mt-5' />
                </>
            }
            { ['approve_form'].includes(view) &&
                <div>                           
                    <div className="flex  mb-3 justify-end items-end">
                        <div>
                            <Button
                                type="button"
                                variant="solid"
                                block
                                color="primary"
                                className='rounded-none rounded-l w-fit'
                                // loading={isLoading}
                                onClick={() => handleApprove('approved')}
                            >
                                Approved
                            </Button>
                        </div>
                        <div>
                            <Button
                                type="button"
                                variant="solid"
                                block
                                color="danger"
                                className='rounded-none  w-fit'
                                // loading={isLoading}
                                onClick={() => handleApprove('reject')}
                            >
                                Reject
                            </Button>
                        </div>
                        <div>
                            <Button
                                type="button"
                                variant="flat"
                                block
                                color="base"
                                className='rounded-none rounded-r'
                                // loading={isLoading}
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>                        
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div className='col-span-2 md:col-span-1'>
                            <Card 
                                className='bg-white'
                                isSearch={false}    
                                title="Deskripsi Iklan"                        
                            >
                                <div className='text-[14px]'>

                                    <div className="grid grid-cols-12 gap-4">
                                        <div className='col-span-4'>Nama Iklan</div>
                                        <div className='col-span-8'> : { selectedCampaign && selectedCampaign.nama }</div>
                                    </div>
                                    <div className="grid grid-cols-12 gap-4 mt-[2px]">
                                        <div className='col-span-4'>Tipe Iklan</div>
                                        <div className='col-span-8'> : { selectedCampaign && selectedCampaign.tipe_display }</div>
                                    </div>
                                    <div className="grid grid-cols-12 gap-4 mt-[2px]">
                                        <div className='col-span-4'>Tanggal Tayang</div>
                                        <div className='col-span-8'> : { selectedCampaign && selectedCampaign.startend_date_display.start_date_display } s.d { selectedCampaign && selectedCampaign.startend_date_display.end_date_display }</div>
                                    </div>
                                    <div className="grid grid-cols-12 gap-4 mt-[2px]">
                                        <div className='col-span-4'>Jam Tayang</div>
                                        <div className='col-span-8'> : { selectedCampaign && selectedCampaign.startend_date_display.start_time_display } s.d { selectedCampaign && selectedCampaign.startend_date_display.end_time_display }</div>
                                    </div>
                                    <div className="grid grid-cols-12 gap-4 mt-[2px]">
                                        <div className='col-span-4'>Anggaran</div>
                                        <div className='col-span-8'> : Rp.{ selectedCampaign && selectedCampaign.anggaran.toLocaleString() }</div>
                                    </div>
                                </div>
                            </Card>
                            <Card 
                                className='bg-white mt-[15px]'
                                isSearch={false}       
                                title="Konten Iklan"                     
                            >
                                { selectedCampaign && selectedCampaign.tipe === 1 && selectedCampaign.video_display &&
                                    <video
                                        // ref={videoRef}
                                        // ref={el => videoRef.current = el} 
                                        // src={videoLoop[videoIndex]}
                                        // src={`${config.apiBaseUrl.video}/video/?tagId=${post.id}&encrypt=${post.id_display}`}
                                        src={`${selectedCampaign.video_display}`}
                                        controls={true}
                                        // autoPlay
                                        // muted="muted"
                                        // playsInline
                                        // muted
                                        // onEnded={() => handleNextVideo(post)}
                                        // className={`w-auto min-w-full min-h-full max-w-none overflow-hidden object-contain videoInsert`}                  
                                        className={`overflow-hidden w-full  aspect-auto`}
                                    // controls={true}
                                    />
                                }
                                { selectedCampaign && selectedCampaign.tipe === 2 &&
                                    <div className='text-[14px]'>
                                        { selectedCampaign && selectedCampaign.survey_display && selectedCampaign.survey_display.map((post, index) => {
                                            return(
                                                <div key={index}>
                                                    <div className='font-bold'>{post.pertanyaan}</div>
                                                    
                                                    { post.tipe === 1 && 
                                                        <ul className='mt-3'>
                                                            {post.pilihan_jawaban.map((jawaban, indexJawaban) => {
                                                                return(
                                                                    <li key={indexJawaban} className='flex gap-4'>
                                                                        <div>{jawaban.key}.</div>
                                                                        <div>{jawaban.value}</div>
                                                                    </li>        
                                                                )
                                                            })}
                                                        </ul>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </Card>
                            <Card 
                                className='bg-white mt-[15px]'
                                isSearch={false}       
                                title="Approve History"                     
                            >
                                <table className='w-full'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Tanggal</th>
                                            <th>State</th>
                                            <th>PIC</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                        <div className="col-span-2 md:col-span-1 bg-white p-[16px] flex flex-col pl-[25px] dark:bg-base-600">
                            <div className="relative">
                                <MapComp
                                    google={props.google}
                                    zoom={15}
                                    init_lat={initLang.lat}
                                    init_lng={initLang.lng}
                                    // style={style}
                                    // onClick={handleClickMaps}
                                    showMarker={true}
                                    containerStyle={{ position: "relative", height: "60vh" }}                                
                                >
                                    { selectedCampaign && selectedCampaign.campaign_wilayah_display && selectedCampaign.campaign_wilayah_display.map((post, index) => {
                                        return (
                                            <Circle
                                                key={index}
                                                center={{ lat: post.lat, lng: post.lon }}
                                                radius={500}
                                                // strokeColor="#d40020"
                                                strokeColor="#FF0000"
                                                strokeOpacity={0.8}
                                                strokeWeight={2}
                                                fillColor="#FF0000"
                                                fillOpacity={0.35}
                                            >
                                                <Marker
                                                    key={index}
                                                    position={{
                                                        lat: post.lat,
                                                        lng: post.lon
                                                    }}
                                                />
                                            </Circle>
                                        )
                                    })}
                                </MapComp>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {['waiting_approval', 'approved', 'all', 'reject'].includes(view) &&
                <Card
                    title={getTitle(view)}
                    isSearch={true}
                    onChangeSearch={(value) => setCampaigns(campaigns => ({
                        ...campaigns,
                        search: value
                    }))}
                    searchValue={campaigns.search}
                    handleSearch={handleSearch}
                >
                    {campaigns.isLoading ?
                        <div>
                            <Loading variant={'skeleton'} loadingClassName='h-[30px] w-full' />
                            <div className="flex w-full gap-4">
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            </div>
                            <div className="flex w-full gap-4">
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            </div>
                        </div>
                        :
                        <Fragment>
                            <Tables>
                                <Tables.Head className='bg-[#efeeee]'>
                                    <tr>
                                        <Tables.Header className='text-[13px] text-right'>#</Tables.Header>
                                        <Tables.Header className='text-[13px]'>Campaign</Tables.Header>
                                        <Tables.Header className='text-[13px]'>Owner</Tables.Header>
                                        <Tables.Header className='text-[13px]'>Approved</Tables.Header>
                                        <Tables.Header className='text-[13px]'>Tanggal Tayang</Tables.Header>
                                        <Tables.Header className='text-[13px]'>Jam Tayang</Tables.Header>
                                        <Tables.Header className='text-[13px] text-right'>Anggaran</Tables.Header>
                                        <Tables.Header className='text-[13px] text-right'>Anggaran Terpakai</Tables.Header>
                                        <Tables.Header className='text-[13px] text-center'>Action</Tables.Header>
                                    </tr>
                                </Tables.Head>
                                <Tables.Body>
                                    {listData && listData.listData.map((post, index) => {
                                        return (
                                            <Tables.Row key={index}>
                                                <Tables.Data>{index + 1 + listData.offset}</Tables.Data>
                                                <Tables.Data>{post.nama}</Tables.Data>
                                                <Tables.Data>{post.owner_display}</Tables.Data>
                                                <Tables.Data>
                                                    <div className={`text-center text-[12px] ${approveColor(post.approved_state_display.approve_state)} px-[8px] py-[2px] w-fit rounded-full`}>
                                                        {post.approved_state_display.state_display}
                                                    </div>
                                                </Tables.Data>
                                                <Tables.Data>
                                                    {post.startend_date_display.start_date_display} s.d {post.startend_date_display.end_date_display}
                                                </Tables.Data>
                                                <Tables.Data>
                                                    {post.startend_date_display.start_time_display} s.d {post.startend_date_display.end_time_display}
                                                </Tables.Data>
                                                <Tables.Data className='text-right'>
                                                    Rp.{post.anggaran.toLocaleString()}
                                                </Tables.Data>
                                                <Tables.Data className='text-right'>
                                                    Rp.{post.anggaran_terpakai.toLocaleString()}
                                                </Tables.Data>
                                                <Tables.Data className='text-center'>
                                                    {/* {!post.is_approved && */}
                                                        <Tooltip tooltip={"Approve / Reject"} delay={500}>
                                                            <ButtonRipple
                                                                color={themeColor}
                                                                className='bg-primary text-white px-[5px] py-[2px] rounded'
                                                                onClick={() => handleShowApprove(post)}
                                                            ><FontAwesomeIcon icon={faCheckCircle} /></ButtonRipple>
                                                        </Tooltip>
                                                    {/* } */}
                                                </Tables.Data>
                                            </Tables.Row>
                                        )
                                    })}
                                </Tables.Body>
                            </Tables>
                        </Fragment>
                    }
                </Card>
            }
        </Fragment>
    )
}
export default IklanPage