import { Button, ButtonDarkMode,  Tooltip } from "../../components";
import FormTextSingleComp  from '../../components/FormTextSingleComp';
import Particles from "react-particles";
import { useLogin } from "./loginFunction";



const Login = () => {
	const { 
		formData, setFormData, handleSubmit, particlesInit, optionparticles,
		themeColor, isLoading
	} = useLogin();
	
	return (
		<div className="relative overflow-hidden">
			<div className="bg-base-100 dark:bg-base-800 absolute inset-0 z-0 transition-colors"></div>

			<div
				style={{
					backgroundColor: themeColor,
				}}
				className="w-24 h-24 absolute rounded-full blur-3xl top-40 left-1/2 -ml-72"
			></div>
			<div
				style={{
					backgroundColor: themeColor,
				}}
				className="w-20 h-20 absolute rounded-full blur-3xl bottom-40 left-1/2"
			></div>

			<Particles
				className="absolute inset-0 z-10"
				id="tsparticles"
				init={particlesInit}
				options={optionparticles}
			/>

			<div
				style={{
					maskImage: `linear-gradient(to left top, transparent, black)`,
					WebkitMaskImage: `linear-gradient(to left top, transparent, black)`,
				}}
				className="absolute z-10 inset-0 h-full w-full bg-white/50 dark:bg-black/50 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]"
			></div>

			<div className="relative w-screen h-screen z-20 overflow-hidden flex bg-white/10 dark:bg-base-700/10 text-base-300  dark:text-base-200 font-light">
				<div className="flex w-full items-center justify-center p-10">
					<div className="w-full md:w-96 h-fit p-10 bg-white/10 dark:bg-base-700/10 backdrop-blur rounded-lg border border-base-100 dark:border-base-600 shadow-lg">
						<div className="font-semibold tracking-wide">Hey,</div>
						<div className="font-semibold tracking-wide">Login Now!</div>
						<div className="tracking-wide text-xs">
							Welcome back, Login to your account.
						</div>

						<br />

						
						<div className="mb-2 flex flex-col gap-2">
							<FormTextSingleComp
								{...formData.username}
								className="bg-[#BABCBA80]"
								formData={formData}
								setFormData={setFormData}
							/>
							<FormTextSingleComp
								{...formData.password}
								className="bg-[#BABCBA80]"
								formData={formData}
								setFormData={setFormData}
							/>
						</div>

						<br />
						<Button
							type="submit"
							variant="flat"
							block
							color="primary"
							loading={isLoading}
							onClick={handleSubmit}
						>
							Login
						</Button>
						
					</div>
				</div>

				<div className="absolute right-10 bottom-10 rounded-full dark:border-base-500">
					<Tooltip tooltip="Dark Mode" placement="left" delay={500}>
						<ButtonDarkMode />
					</Tooltip>
				</div>
			</div>
		</div>
	);
};

export default Login;
