// eslint-disable-next-line 
export default {    
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {                
        development: "http://127.0.0.1:8000",
        production: "https://fwwbe.myappscenter.com", 
        // development: "http://192.168.4.126:8000",
        // production: "http://192.168.4.126:8000", 
        // development: "http://192.168.18.108:8000",
        // production: "http://192.168.18.108:8000", 
        // development: "http://192.168.1.2:8000",
        // production: "http://192.168.1.2:8000", 
        // development: "https://d85e-2404-8000-1000-e42-fc1f-9b4f-6a1b-7f8c.ngrok-free.app",
        // production: "https://d85e-2404-8000-1000-e42-fc1f-9b4f-6a1b-7f8c.ngrok-free.app",
        // development: "http://103.179.56.148:8000",
        // production: "http://103.179.56.148:8000", 
        // video: "http://127.0.0.1:4000", 
        // video: "http://localhost:4000", 
        // development: "https://dataaaf.myappscenter.com",
        // production: "https://dataaaf.myappscenter.com", 
    },
    localStorageKey : {      
      USER: "__apjiiClientUser",
      ACCESS_TOKEN: "__apjiiClientToken",      
      PERUSAHAAN : '_apjiiClientperusahaanInfo',
    },
    initLocation : {
      lat : -6.892220050781338, 
      lng : 107.56976029847955
    },
    cookiesName : 'fww_media',    
    cookiesNameToken : 'fwwmedia_token',    
    expiresSession : 86400,
    itemPerPage : 10,
    timeOutValue : 10000,
    tinyApiKey : 't8gfjoqtatjczsydjihqzwjh8o3g4sgi2khc4ij6gtpsnqab',
    googleMapKey : 'AIzaSyDm751ucwcFMFw6dztSqEfgCKeYoz2hTYc',       
    endPoint : {
      // auth
      permissionGroup : '/auth/api/v1/group-permissions/',
      login : `/auth/api/login/`,      

      // ads
      postCampaign : `/ads/api/v1/post-campaign/`,
      paymentGatewayMethod : `/master/api/v1/payment-gateway-method/`,
      campaign : `/ads/api/v1/campaign/`,
      campaignApproveLog : `/ads/api/v1/campaign-approve-log/`,
      campaignApproveAction : `/ads/api/v1/campaign-approve-action/`,
      settings : `/master/api/v1/setting/`,
      campaignLog : `/ads/api/v1/campaign-log/`,

      resto : `/resto/api/v1/master-resto/`,

      // settings : `/master/api/v1/setting/`,
      masterRouter : `/master/api/v1/routers/`,
      // kontaks
      kontaks : `/kontak/api/v1/kontak/`,
      saldoLogs : `/kontak/api/v1/kontak-saldo-log/`,
    }
}