import DashboardPage from "../views/Dashboard/DashboardPage";
import {
	TbSmartHome,
} from "react-icons/tb";
import PaymentGatewayPage from "../views/Master/PaymentGateway/PaymentGateway";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faSliders, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import IklanPage from "../views/Iklan/Pages/IklanPages";
import SettingPage from "../views/Master/Settings/SettingPage";
import ClientPage from "../views/Client/ClientPage/ClientPage";
import IklanTayangPage from "../views/Iklan/IklanTayang/IklanTayangPage";
import DepositePage from "../views/Client/Deposite/DepositePage";
import RoutersPage from "../views/Master/Routers/RoutersPage";
import RestoPage from "../views/Client/RestoPage/RestoPage";

export const menu = [
	{
		icon: <TbSmartHome />,
		path: "/",
		name: "dashboard",
		title: "Dashboard",
		element: <DashboardPage />,
		sub: [],
	},		
	{
		icon: <FontAwesomeIcon icon={faSliders} />,
		path: "settings",
		name: "settings",
		title: "Settings",
		element: null,
		sub: [
			{
				icon: <FontAwesomeIcon icon={faCreditCard} />,
				path: "/settings/payment-gateway",
				name: "payment-gateway",
				title: "Payment Gateway",
				element: <PaymentGatewayPage />,
				sub: [],
			},
			{
				icon: <FontAwesomeIcon icon={faCreditCard} />,
				path: "/settings/setting",
				name: "setting",
				title: "Setting",
				element: <SettingPage />,
				sub: [],
			},
			{
				icon: <FontAwesomeIcon icon={faCreditCard} />,
				path: "/settings/routers",
				name: "routers",
				title: "Routers",
				element: <RoutersPage />,
				sub: [],
			},
		],
	},
	{
		icon: <FontAwesomeIcon icon={faBullhorn} />,
		path: "ads",
		name: "ads",
		title: "Iklan",
		element: null,
		sub: [
			{
				icon: <FontAwesomeIcon icon={faCreditCard} />,
				path: "/ads/ads",
				name: "ads",
				title: "Iklan",
				element: <IklanPage />,
				sub: [],
			},
			{
				icon: <FontAwesomeIcon icon={faCreditCard} />,
				path: "/ads/show",
				name: "adsShow",
				title: "Iklan Tayang",
				element: <IklanTayangPage />,
				sub: [],
			},
		],
	},
	{
		icon: <FontAwesomeIcon icon={faUsers} />,
		path: "client",
		name: "client",
		title: "Client",
		element: null,
		sub: [
			{
				icon: <FontAwesomeIcon icon={faCreditCard} />,
				path: "/client/client",
				name: "client",
				title: "Client",
				element: <ClientPage />,
				sub: [],
			},
			{
				icon: <FontAwesomeIcon icon={faCreditCard} />,
				path: "/client/deposite",
				name: "deposite",
				title: "Deposite",
				element: <DepositePage />,
				sub: [],
			},
			{
				icon: <FontAwesomeIcon icon={faCreditCard} />,
				path: "/client/cafe",
				name: "cafe",
				title: "Cafe",
				element: <RestoPage />,
				sub: [],
			},
		],
	},
];
