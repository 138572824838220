import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import moment from "moment/moment";
import { useContext, useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { TextField } from "..";
import { ThemeContext } from "../../context/ThemeContext";

/**
 *
 * @param {{
 * id: string;
 * name: string;
 * label: string;
 * value: string;
 * disabled: boolean;
 * placeholder: string;
 * variant: "basic" | "outline" | "underlined" | "filled";
 * size: "sm" | "md" | "lg" | "xl";
 * color: "primary" | "base" | "success" | "warning" | "danger" | "info";
 * rounded: "none" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl";
 * density: "tight" | "normal" | "loose";
 * prefix: React.ReactNode;
 * suffix: React.ReactNode;
 * prepend: React.ReactNode;
 * append: React.ReactNode;
 * note: React.ReactNode;
 * error: React.ReactNode;
 * mode: "single" | "multiple" | "range";
 * fromYear: number;
 * toYear: number;
 * value: string;
 * setValue: React.Dispatch<React.SetStateAction<string>>;
 * onChange: React.ChangeEventHandler<HTMLInputElement>;
 * }}
 *
 */

const DatePicker = ({
	id,
	name,
	label,
	disabled,
	placeholder,
	variant = "basic",
	size,
	color = "primary",
	rounded = "md",
	density = "normal",
	prefix,
	suffix,
	prepend,
	append,
	note,
	error,
	mode = "single",
	fromYear,
	toYear,
	value,
	setValue,
	onChange,
}) => {
	const { themeColor } = useContext(ThemeContext);
	const year = moment().year();
	const fromyear = fromYear || 2015;
	const toyear = toYear || year + 2;

	const [open, setOpen] = useState(false);

	const ref = useRef();
	useOnClickOutside(ref, () => setOpen(false));

	// Color
	const datepickerColor =
		{
			primary: themeColor,
			base: "#BABCBD",
			success: "#4ED17E",
			warning: "#EEC239",
			danger: "#F26969",
			info: "#629BF8",
		}[color] || color;

	return (
		<div ref={ref} className="relative">
			<TextField
				id={id}
				name={name}
				label={label}
				disabled={disabled}
				placeholder={placeholder}
				variant={variant}
				size={size}
				color={color}
				rounded={rounded}
				density={density}
				prefix={prefix}
				suffix={suffix}
				prepend={prepend}
				append={append}
				note={note}
				error={error}
				value={
					mode === "range"
						? value?.join(" - ")
						: mode === "multiple"
						? value?.join(", ")
						: value
						? moment(value).format("DD/MM/YYYY")
						: ""
				}
				onClick={() => setOpen(true)}
				setValue={setValue}
				readOnly
			/>

			{open && (
				<div className="absolute top-full bg-white dark:bg-base-700 w-fit rounded-md shadow-lg border border-base-50 dark:border-base-500 z-10">
					<DayPicker
						className="m-0 p-4"
						selected={value}
						onSelect={setValue}
						onDayClick={onChange}
						modifiersStyles={{
							selected: {
								backgroundColor: datepickerColor,
								color: "#fff",
							},
						}}
						mode={mode}
						captionLayout="dropdown-buttons"
						fromYear={fromyear}
						toYear={toyear}
						showOutsideDays
					/>
				</div>
			)}
		</div>
	);
};

export default DatePicker;
