import { useCallback, useState } from "react";
import api from "../../api";
import config from "../../config";
import { pageObj } from "../../generalFunction/pageObj";



export function useRouter(){
    const [routers, setRouters] = useState({
        pageObj : [],
        listData : [],
        lastPage : 0,
        offset : 0,
        page : 1,
        totalRecords : 0,
        search : '',
        isLoading : true,
        isError : false,
        errorMsg : '',
        errorStatus : null,
    })

    const fetchRouters = useCallback(async({q='', offset=0, limit=config.itemPerPage, variable=null, tipe_fetching='update'}) => {        
        setRouters(routers => ({...routers, isLoading: true, listData : []}))        
        try{
            let params = {
                offset : offset,
                limit : limit
            };
            if (q){
                params['q'] = q
            }
            if (variable){
                params = {
                    ...params,
                    ...variable
                }                
            }
            const res = await api.get(`${config.endPoint.masterRouter}`, { params : params}).then(res => res.data);            
            if (res){
                if (tipe_fetching === 'update'){
                    setTimeout(() => {
                        setRouters(routers => ({...routers,
                            pageObj : pageObj(res.count, limit, offset),
                            listData : res.results,
                            lastPage : Math.ceil(parseInt(res.count) / parseInt(limit)),
                            totalRecords : res.count,
                            isLoading : false
                        }))
                    }, 500)
                }
                return res
            }
        } catch (error) {
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;
            let errorStatus = null
            if (response && response.data && response.data.message){
                msg = response.data.message
            }         
            if (response && response.status){
                errorStatus = response.status
            }   
            
            setRouters(routers => ({...routers, isError : true, errorMsg : msg, isLoading : false, errorStatus: errorStatus, lastPage: 0, pageObj : []}))
            return error
        }
    },[])

    const paginateRouters = (page) => {
        
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setRouters(routers => ({...routers,
            page : page, 
            offset: myOffset        
        }))
        
        fetchRouters(routers.search, myOffset, config.itemPerPage);        
    }

    return {
        routers, setRouters, fetchRouters, paginateRouters
    }
}