import Proptypes from 'prop-types';
import Search from '../atoms/Search';
import Loading from '../atoms/Loading';
import { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';


const Card = (props) => {
    const { children, 
        title, 
        isSearch, 
        isCreate,
        handleSubmit, 
        handleCancel,
        onChangeSearch, 
        handleSearch, 
        searchValue,
        handleCreate,
        isLoading,
        loadingHeight,
        loadingWidth,
        loadingColor,
        loadingClassName,
        className,
    } = props;

    const { themeColor } = useContext(ThemeContext)

    const bgColor = {
        "#7367f0" : `bg-[#7367f0]`,
        "#f43f5e" : `bg-[#f43f5e]`,
        "#3b82f6" : `bg-[#3b82f6]`,
        "#14b8a6" : `bg-[#14b8a6]`,
        "#eab308" : `bg-[#eab308]`,
    }
    return(
        <div className={`shadow-lg bg-white dark:bg-base-600  rounded-md relative ${className}`}>
            {/* { title &&
                <div className='px-5 py-4 border-b border-b-gray-200'>{title}</div>
            } */}
            { (title || isCreate)  &&
                <div className='grid-cols-2 px-5 py-4 border-b border-b-gray-200 gap-4 md:flex md:flex-row md:justify-between w-full'>
                    <div className='col-span-2 md:col-span-1 font-bold text-[16px] flex gap-4 items-center'>
                        <div>
                            {title} 
                        </div>
                        { isLoading &&
                            <div>
                                <Loading
                                    height={loadingHeight}
                                    width={loadingWidth}
                                    color={loadingColor || themeColor}
                                    loadingClassName={loadingClassName}
                                />
                            </div>
                        }
                    </div>
                    <div className="col-span-2  md:mt-0 md:col-span-1 text-right">
                        {isCreate ?
                            <div className="flex flex-row justify-end">
                                { handleSubmit &&
                                    <button onClick={handleSubmit} className={`px-5  py-[7px] ${bgColor[themeColor]} text-white text-[14px] rounded-l`}>Simpan</button>
                                }
                                { handleCancel &&
                                    <button onClick={handleCancel} className='px-5  py-[7px] bg-gray-400 text-white text-[14px] rounded-r'>Cancel</button>
                                }

                            </div>
                            :
                            <div className="w-full flex gap-2 mt-2">
                                { isSearch &&
                                    <div className="flex w-full bg-blue-100 md:mr-2">
                                        <Search
                                            placeholder={"Cari"}
                                            onChange={(value) => onChangeSearch(value)}
                                            handleSearch={handleSearch}
                                            value={searchValue}
                                        />                                              
                                    </div>
                                }
                                { handleCreate &&                                    
                                    <button onClick={handleCreate} className={`flex flex-row items-center  px-5  py-[0px] ${bgColor[themeColor]} text-white text-[14px] rounded`}>
                                        <FontAwesomeIcon icon={faPlus} />
                                        <div className='invisible md:visible w-0 md:w-fit'>
                                            Tambah
                                        </div>
                                    </button>
                                }
                            </div>
                        }
                    </div>
                </div>
            }
            <div className='p-6 py-8'>
                { children }
            </div>
        </div>
    )
}
Card.propTypes = {
    children : Proptypes.any,
    title : Proptypes.any,    
    isCreate : Proptypes.bool,
    isSearch : Proptypes.bool,
    handleSubmit : Proptypes.func,
    handleCancel : Proptypes.func,
    onChangeSearch : Proptypes.func,
    handleSearch : Proptypes.func, 
    searchValue : Proptypes.string,
    handleCreate : Proptypes.func, 
    isLoading : Proptypes.bool,
    loadingHeight : Proptypes.number,
    loadingWidth : Proptypes.number,
    loadingColor : Proptypes.string,
    loadingClassName : Proptypes.string,
    className : Proptypes.string,
}

Card.defaultProps = {    
    isCreate : false,
    isSearch : true,
    isLoading : false,
}
export default Card