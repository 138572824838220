import { createContext,  } from "react";
// import config from "../config";
// import api from "../api";
// import { pageObj } from "../generalFunction/pageObj";
import { useCampaign } from "./getData/campaign";
import { useCampaignApproveLogs } from "./getData/campaign_approve_logs";
import { useSettings } from "./getData/settings";
import { useKontaks } from "./getData/kontaks";
import { useCampaignLogs } from "./getData/campaign_logs";
import { useSaldoLogs } from "./getData/saldo_logs";
import { useRouter } from "./getData/master_routers";
import { useRestoFetching } from "./getData/resto";


export const DataContext = createContext([{}, () => {}]);

export const DataProvider = ({ children }) => {
    const { campaigns, setCampaigns, fetchCampaign, paginateCampaign } = useCampaign()
    const { campaignApproveLogs, setCampaignApproveLogs, fetchCampaignApproveLogs, paginateCampaignApproveLogs } = useCampaignApproveLogs()
	const { settings, setSettings, fetchSettings, paginateSettings } = useSettings();
	const { kontaks, setKontaks, fetchKontak, paginateKontak, fetchKontakDetail } = useKontaks();
	const { 
		campaignLogs, setCampaignLogs, fetchCampaignLogs, paginateCampaignLogs, 
	} = useCampaignLogs();
	const { routers, setRouters, fetchRouters, paginateRouters } = useRouter();
    const { 
		saldoLogs, setSaldoLogs, fetchSaldoLogs, paginateSaldoLogs, 
	} = useSaldoLogs();
	const { resto, setResto, fetchResto, paginateResto } = useRestoFetching();

	

    return (
		<DataContext.Provider
			value={{
				campaigns, setCampaigns, fetchCampaign, paginateCampaign,
        		campaignApproveLogs, setCampaignApproveLogs, fetchCampaignApproveLogs, paginateCampaignApproveLogs,
				settings, setSettings, fetchSettings, paginateSettings,
				kontaks, setKontaks, fetchKontak, paginateKontak, fetchKontakDetail,
				campaignLogs, setCampaignLogs, fetchCampaignLogs, paginateCampaignLogs, 
				saldoLogs, setSaldoLogs, fetchSaldoLogs, paginateSaldoLogs, 
				routers, setRouters, fetchRouters, paginateRouters,
				resto, setResto, fetchResto, paginateResto,				
			}}
		>
			{children}
		</DataContext.Provider>
	);

}