import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import { DataContext } from "../../../context/DataContext";
import config from "../../../config";
import api from "../../../api";

export function useSetting(){
    const { 
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading,  bgColor
    } = useGlobalFunction();
    const [listData, setListData] = useState([]);
    const { fetchSettings, settings, } = useContext(DataContext);

    const fetchData = useCallback(async() => {
        setIsLoading(true);
        try{
            let res = await fetchSettings('', 0, config.itemPerPage, null)
            let tmp = []
            if (res) {
                res.results.map(post => {
                    let obj = {...post}
                    obj['showError'] = false;
                    obj['required'] = true;
                    obj['value'] = post.value || '';
                    tmp.push(obj)
                    return true;
                })
            }
            setListData(tmp);
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Error', subText : 'Unable to load data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    },[setAlertValue])

    useEffect(() => {
        fetchData();
    },[fetchData])

    const onChange = (e, index) => {
        const { value } = e.target;
        let array = [...listData];
        array[index] = {...array[index], value: value}
        setListData(array);
    }

    const onBlur = (e, index) => {
        const { value } = e.target;
        let array = [...listData];
        if (array[index].required === true && value === ''){
            array[index] = {...array[index], showError : true}
        }else{
            array[index] = {...array[index], showError : false}
        }
        setListData(array);
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let array = [...listData];
            array.map(post => {
                if (post.value === ''){
                    setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', subText : `Lengkapi data ${post.deskripsi}`, color : 'danger'}));
                    setTimeout(() => {
                        setAlertValue(alertValue => ({...alertValue, show: false}));
                    }, config.timeOutValue)
                    setIsLoading(false);
                    return false;
                }
                return true;
            })
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(listData));
            const res = await api.post(`${config.endPoint.settings}`, newForm).then(res => res.data);
            if (res){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Success', subText : `Data Tersimpan dengan ini dikatan bawah sudah bisa berdiri sendiri dan lagi juga`, color : 'success'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}));
                }, config.timeOutValue)
                setIsLoading(false);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Error', subText : 'Unable to save data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, config.timeOutValue)
        }
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading,  bgColor, listData, settings, setListData, onChange, onBlur, handleSubmit
    }
}