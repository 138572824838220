import axios from 'axios';
import config from './config';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


const headers = { 'Accept' : 'application/json', 'Content-Type' : 'application/json' }



const api = axios.create({
    baseURL : config.apiBaseUrl[process.env.NODE_ENV],
    headers: headers,    
    timeout : 50000
});


api.interceptors.request.use(
    function(configure) {        
        const myCookies = cookies.get(config.cookiesName);             
        if (myCookies && myCookies.token) {                    
            configure.headers["Authorization"] = 'Token ' + myCookies.token;
            configure.headers["content-type"] = 'multipart/form-data';
        }
        return configure;
    },
    function(error) {                
        // return error;        
        return Promise.reject(error.response); 
    },
    (error) => {        
        console.log('error from api is >>>>', error)
        return error
    }
);



export default api;