import {  useState } from "react";
import Cookies from 'universal-cookie';
import config from "../config";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export function  useGlobalFunction(){
    const [isLoading, setIsLoading] = useState(false);
    const cookies = new Cookies();
    const [alertValue, setAlertValue] = useState({
        show : false,
        text : 'Peringatan',
        subText : 'Tindakan ini akan menjadi sebuah pelajaran',
        color : 'primary',
        icon : faCheckCircle
    });
    const [modalValue, setModalValue] = useState({
        show: false,
        tipe: '',
        content : 'list',
        text : '',
        title : '',
        id : 0,
        params : null,
        url : null,
        isError : false
    })

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({...alertValue, show: false, text : ''}))
    }

    const [pageValue, setPageValue] = useState({
		page : 1,
		search : '',
		offset : 0,
		obj : [],        
        lastPage : 1,        
	});
    

    const handleCloseModal = () => {
        setModalValue({
            show: false,
            tipe: '',
            content : 'list',
            text : '',
            title : '',
        })
    }

    const catchFetching = (error, page) => {
        let status = error && error.response && error.response.status ? error.response.status : '';
        if (status && [401, 403].includes(status)) {            
            cookies.remove(config.cookiesName, { path: '/' });	
            if (page === 'admin'){
                return console.log("ga login")
                // return window.location.href = "/admin/login"
            }else{
                return console.log("ga login member")
                // return window.location.href = "/member/login"
            }
        }
    }    

    const bgColor = {
        "#7367f0" : `bg-[#7367f0]`,
        "#f43f5e" : `bg-[#f43f5e]`,
        "#3b82f6" : `bg-[#3b82f6]`,
        "#14b8a6" : `bg-[#14b8a6]`,
        "#eab308" : `bg-[#eab308]`,
    }

    const bgStatusResto = (params) => {
        if (params === 1){
            return `bg-red-100 text-red-700`
        }else if(params === 2){
            return `bg-orange-400 text-black`        
        }else if(params === 3){
            return `bg-green-100 text-green-700`        
        }else if(params === 4){
            return `bg-gray-700 text-white`
        }
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, bgColor, bgStatusResto
    }
}