import { useContext, useEffect, useMemo, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
// import api from "../../../api";
// import config from "../../../config";
// import { pageObj } from "../../../generalFunction/pageObj";
import { DataContext } from "../../../context/DataContext";
import config from "../../../config";
import { useNavigate } from "react-router-dom";
import api from "../../../api";
import Moment from 'moment';
import axios from "axios";
import Cookies from 'universal-cookie';
// import { useNavigate } from "react-router-dom";


export function useIklan(props) {
    const {
        alertValue, setAlertValue, handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal,
        isLoading, setIsLoading, bgColor
    } = useGlobalFunction();
    const cookies = new Cookies();
    const [view, setView] = useState('waiting_approval');
    const [totalWaiting, setTotalWaiting] = useState({ isLoading: true, count: 0 });
    const [totalApproved, setTotalApproved] = useState({ isLoading: true, count: 0 });
    const [totalAll, setTotalAll] = useState({ isLoading: true, count: 0 });
    const { fetchCampaign, campaigns, setCampaigns,
        campaignApproveLogs, fetchCampaignApproveLogs
    } = useContext(DataContext)
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [oldView, setOldView] = useState(null);    
    
    const navigate = useNavigate();
    const [initLang, setInitLang] = useState({
        lat: config.initLocation.lat,
        lng: config.initLocation.lng
    });

    useEffect(() => {
        fetchCampaignApproveLogs();
    }, [fetchCampaignApproveLogs])

    useEffect(() => {
        async function fetchAll() {
            let res = await fetchCampaign('', 0, config.itemPerPage, null)
            if (res) {
                setTotalAll(totalAll => ({ ...totalAll, isLoading: false, count: res.count }))
            }
        }
        async function fetchWaiting() {
            let res = await fetchCampaign('', 0, config.itemPerPage, { 'tipe': 'waiting_approval' }, null)
            if (res) {
                setTotalWaiting(totalWaiting => ({ ...totalWaiting, isLoading: false, count: res.count }))
            }
        }
        async function fetchApproved() {
            let res = await fetchCampaign('', 0, config.itemPerPage, { 'tipe': 'approved' }, null)
            if (res) {
                setTotalApproved(totalApproved => ({ ...totalApproved, isLoading: false, count: res.count }))
            }
        }
        async function fetchReject() {
            let res = await fetchCampaign('', 0, config.itemPerPage, { 'tipe': 'reject' }, null)
            if (res) {
                setTotalApproved(totalApproved => ({ ...totalApproved, isLoading: false, count: res.count }))
            }
        }
        fetchAll();
        fetchWaiting();
        fetchApproved();
        fetchReject();
    }, [fetchCampaign])

    const listData = useMemo(() => {
        // let tmp = null;
        if (campaigns && campaigns.isError && [401, 403].includes(campaigns.errorStatus)) {
            // navigate('/login')            
            return window.location.href = "/login"
            // console.log('campaign error >>>>', campaigns)
        }
        return campaigns
        // eslint-disable-next-line
    }, [campaigns])

    useEffect(() => {
        // if (view === 'waiting_approval') {
        //     fetchCampaign('', 0, config.itemPerPage, { 'tipe': 'waiting_approval' });
        // } else if (view === 'approved') {
        //     fetchCampaign('', 0, config.itemPerPage, { 'tipe': 'approved' });
        // } else {
        //     fetchCampaign('', 0, config.itemPerPage);
        // }
        fetchCampaign('', 0, config.itemPerPage, { 'tipe': 'waiting_approval' });
        // eslint-disable-next-line
    }, [fetchCampaign])



    const getTitle = (params) => {
        if (params === 'waiting_approval') {
            return "Waiting Approval";
        } else if (params === 'approved') {
            return "Approved"
        } else {
            return "All"
        }
    }

    const handleChangeView = (params) => {
        setView(params);
        if (params === 'all') {
            fetchCampaign('', 0, config.itemPerPage)
        } else if (params === 'waiting_approval') {
            fetchCampaign('', 0, config.itemPerPage, { 'tipe': 'waiting_approval' })
        } else if (params === 'reject'){
            fetchCampaign('', 0, config.itemPerPage, { 'tipe': 'reject' })
        }else {
            fetchCampaign('', 0, config.itemPerPage, { 'tipe': 'approved' })
        }
    }

    const handleSearch = () => {
        let tmp = null;
        if (view === 'waiting_approval') {
            tmp = {
                'tipe': 'waiting_approval'
            }
        } else if (view === 'approved') {
            tmp = {
                'tipe': 'approved'
            }
        }
        fetchCampaign(campaigns.search, 0, config.itemPerPage, tmp)
        // setCampaigns(campaigns => ({...campaigns, offset : 0, page : 1}))
    }

    const handleShowApprove = (post) => {        
        setOldView(view);
        setSelectedCampaign(post);
        setView('approve_form');
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = () => {
            window.history.pushState(null, "", window.location.href);
        };
    }

    const handleCancel = () => {
        setView(oldView)
    }

    
    const closeQuickView = () => {
        handleCloseModal()
        if (['all', 'approved', 'waiting_approval'].includes(view)) {
            window.onpopstate = undefined;
            navigate(-1)
        } else {
            setView(oldView)
        }
    }


    useEffect(() => {
        window.addEventListener("popstate", closeQuickView);
        return () => window.removeEventListener('popstate', closeQuickView);
        // eslint-disable-next-line
    }, [view])

    const handleChangeAlasan = (e) => {
        const { value } = e.target;
        setModalValue(modalValue => ({...modalValue, params : value}))
    }
    
    const handleBlurAlasan = (e) => {
        const { value } = e.target;
        if (value === ''){
            setModalValue(modalValue => ({...modalValue, isError : true}))
        }else{
            setModalValue(modalValue => ({...modalValue, isError : false}))
        }
    }
    

    const handleSubmitApprove = async () => {
        setIsLoading(true)
        try {
            let tmp = {}
            if (modalValue.tipe === 'approved'){
                tmp['is_approve'] = true
            }else{
                if (modalValue.params === '' || !modalValue.params){
                    setModalValue(modalValue => ({...modalValue, isError : true}))
                    setAlertValue(alertValue => ({...alertValue, show: true, text : "Lengkapi data", color : 'danger', 
                        subText : (<div>
                            <div>Isi semua data yang diperlukan</div>
                        </div>)
                    }));
                    setTimeout(() => {
                        setAlertValue(alertValue => ({...alertValue, show: false}));
                    }, config.timeOutValue)
                    setIsLoading(false);                    
                    return;
                }                
                tmp['is_approve'] = false
                tmp['alasan'] = modalValue.params                
            }
            
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp))
            const res = await api.put(`${config.endPoint.campaignApproveActionsss}${selectedCampaign.id}/`, newForm).then(res => res.data);
            if (res) {

                let resAll = await fetchCampaign('', 0, config.itemPerPage, null)
                if (resAll) {
                    setTotalAll(totalAll => ({ ...totalAll, isLoading: false, count: resAll.count }))
                }

                let resWaiting = await fetchCampaign('', 0, config.itemPerPage, { 'tipe': 'waiting_approval' }, null)
                if (resWaiting) {
                    setTotalWaiting(totalWaiting => ({ ...totalWaiting, isLoading: false, count: resWaiting.count }))
                }

                let resApproved = await fetchCampaign('', 0, config.itemPerPage, { 'tipe': 'approved' }, null)
                if (resApproved) {
                    setTotalApproved(totalApproved => ({ ...totalApproved, isLoading: false, count: resApproved.count }))
                }

                let params = oldView
                if (params) {
                    fetchCampaign(campaigns.search, campaigns.offet, config.itemPerPage, { 'tipe': params });
                } else {
                    fetchCampaign(campaigns.search, campaigns.offet, config.itemPerPage);
                }
                setTimeout(() => {
                    setView(oldView);
                }, 500)
                handleCloseModal();
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;            
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            
            setAlertValue(alertValue => ({ ...alertValue, show: true, text : 'Error', subText: msg, color: 'danger' }))
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    }

    const handleApprove = (params) => {        
        if (params === 'approved'){
            setModalValue(modalValue => ({
                ...modalValue,
                show: true, tipe: 'approved', text: `Apakah anda yakin akan melakukan approve untuk iklan ${selectedCampaign && selectedCampaign.nama ? selectedCampaign.nama : ''} ?`,
                id: selectedCampaign ? selectedCampaign.id : null,
                content : 'approved'
            }))
        }else{
            setModalValue(modalValue => ({
                ...modalValue,
                show: true, tipe: 'reject', text: `Apakah anda yakin akan melakukan Reject untuk iklan ${selectedCampaign && selectedCampaign.nama ? selectedCampaign.nama : ''} ?`,
                id: selectedCampaign ? selectedCampaign.id : null,
                content : 'reject'
            }))
        }
    }

    // =========================== create iklan ============================================
    const [formData, setFormData] = useState({
        nama : {            
            name: 'nama',
            tipe: 'text',
            type: 'text',
            value: '',
            label: 'Nama Iklan',
            required: true,
            showError: false,                        
        },        
        tipe : {            
            name: 'tipe',
            tipe: 'select',
            type: 'select',
            value: 2,
            label: 'Tipe Iklan',
            required: true,
            showError: false,            
            obj : [
                {value : 1, label : 'Video'},
                {value : 2, label : 'Survey'},
                {value : 3, label : 'Image'},
            ],
        },        
        start_date : {            
            name: 'start_date',            
            type: 'date',
            value: '',
            label: 'Tanggal Tayang Iklan',
            required: true,
            showError: false,                      
        },
        end_date : {            
            name: 'end_date',            
            type: 'date',
            value: '',
            label: 's.d Tanggal',
            required: true,
            showError: false,                      
        },
        start_time : {            
            name: 'start_time',            
            type: 'date',
            value: '',
            label: 'Jam Tayang Iklan',
            required: true,
            showError: false,     
            dateFormat: "HH:mm",
            showTimeSelectOnly:true,
            showTimeSelect: true                 
        },
        end_time : {            
            name: 'end_time',            
            type: 'date',
            value: '',
            label: 's.d Jam',
            required: true,
            showError: false,    
            dateFormat: "HH:mm",
            showTimeSelectOnly:true,
            showTimeSelect: true                  
        },
        file_media : {            
            name: 'file_media',
            tipe: 'file',
            type: 'file',
            value: '',
            label: 'Masukan Video',
            required: false,
            showError: false,     
            accept_file : "video/*"        
        },
        all_location : {            
            name: 'all_location',
            type: 'checked',            
            value: false,
            label: 'Disemua Lokasi',            
            showError: false,
        },
    })
    // const [initPilihan, setInitPilihan]
    const [formPertanyaan, setFormPertanyaan] =  useState({
        pertanyaan : {
            name: 'pertanyaan',
            tipe: 'text',
            type: 'text',
            value: '',
            label: 'Pertanyaan',
            required: true,
            showError: false,                 
        },
        tipe : {
            name: 'tipe',
            tipe: 'select',
            type: 'select',
            value: 1,
            label: 'Tipe Pertanyaan',
            required: true,
            showError: false,
            obj : [
                {value : 1, label : 'Pilihan'},
                {value : 2, label : 'Jawaban'},
            ]
        },
        pilihan_obj : [{value : '', showError: false, key: 'a'}, {value : '', showError : false, key: 'b'}]
    })

    const [locations, setLocations] = useState([]);
    const [videoSrc , setVideoSrc] = useState("");

    const [showMarker, setShowMarker] = useState(false);
    
    const handleClickMaps = (t, map, coord) => {
        const { latLng } = coord;
        let array = [...locations];
        array.push({
            lat : latLng.lat(),
            lng : latLng.lng()
        })
        setLocations(array);        
    }

    const handleDeleteLocation = (index) => {
        let array = [...locations];
        array.splice(index, 1);
        setLocations(array);
    }

    const handleCreate = () => {
        setOldView(view)
        setView('create');
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let tmpForm = {}
            let countError = 0;
            let obj = {...formData}
            let tmpFormPertanyaan = {}
            let jawabans = []

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;
                }else{
                    if (post.type === 'date'){
                        tmpForm[index] = Moment(post.value).format('YYYY-MM-DD HH:mm');
                    }else{
                        tmpForm[index] = post.value;
                    }                       
                }
                return true;
            })
            let headers = {
                'Content-Type': 'multipart/form-data',                
            }
            const myCookies = cookies.get(config.cookiesName);             
            if (myCookies && myCookies.token) {                    
                headers['Authorization'] = 'Token ' + myCookies.token;
            }   
            // return;

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : "Lengkapi data", color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
                setIsLoading(false);
                return;
            }

            if ([2, '2'].includes(formData.tipe.value)){
                let objFormPertanyaan = {...formPertanyaan};
                if ([1, '1'].includes(formPertanyaan.tipe.value)){
                    let errorJawaban = 0
                    let array = [...formPertanyaan.pilihan_obj]
                    array.map((post) => {
                        if (post.value === ''){
                            errorJawaban++;
                            post.showError = true
                        }
                        return true;
                    })

                    if (errorJawaban > 0){
                        setAlertValue(alertValue => ({...alertValue, show: true, text : "Masukan Pilihan Jawaban", color : 'danger'}));
                        setTimeout(() => {
                            setAlertValue(alertValue => ({...alertValue, show: false}))
                        }, config.timeOutValue)
                        setIsLoading(false);    
                        setFormPertanyaan(formPertanyaan => ({...formPertanyaan, pilihan_obj : array}))
                        return;                    
                    }
                }

                Object.entries(objFormPertanyaan).map(([index, post]) => {
                    if (post.required && post.value === ''){
                        post.showError = true;
                        countError++;
                    }else{
                        tmpFormPertanyaan[index] = post.value
                    }
                    return true;
                })
                jawabans = formPertanyaan.pilihan_obj.map(x => ({'value' : x.value, 'key' : x.key}))
            }

            // console.log('jawabans >>>>>', jawabans)
            // return
            if (formData.all_location.value !== true){
                if (locations.length === 0){
                    setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', subText : "Masukkan lokasi dengan cara klik pada peta", color : 'danger'}));
                    setTimeout(() => {
                        setAlertValue(alertValue => ({...alertValue, show: false}))
                    }, config.timeOutValue)
                    setIsLoading(false);
                    return;            
                }
            }

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : "Lengkapi data", color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
                setIsLoading(false);
                return;
            }

            tmpForm['is_office'] = true;
            

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            newForm.append('video', formData.file_media.value);
            newForm.append('formPertanyaan', JSON.stringify(tmpFormPertanyaan))
            newForm.append('locations', JSON.stringify(locations))
            newForm.append('jawabans', JSON.stringify(jawabans))

            
            // const res = await api.post(`${config.endPoint.postCampaign}`, newForm).then(res => res.data);

            const res = await axios.post(`${config.apiBaseUrl[process.env.NODE_ENV]}${config.endPoint.postCampaign}`, newForm, {headers: headers}).then(res => res.data);
            if (res){
                setView(oldView)
            }
        }catch(error){
            setIsLoading(false);
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Error', subText : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, config.timeOutValue)
        }
    }

    const handleChangeVideo = (e) => {
        const file = e.target.files[0];        
        let url = URL.createObjectURL(file)
        setVideoSrc(url);    
        
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function() {			
			setFormData(formData => ({...formData, 
				file_media : {...formData.file_media, value: file},				
			}))
        }
    }

    const handleChangePilihan = (e, index) => {
        const { value, name } = e.target;

        let array = [...formPertanyaan.pilihan_obj]
        array[index][name] = value;
        setFormPertanyaan(formPertanyaan => ({...formPertanyaan, pilihan_obj : array}))
    }

    const handleBlurPilihan = (e, index) => {
        const { value } = e.target;
        let array = [...formPertanyaan.pilihan_obj];
        if (value === ''){
            array[index]['showError'] = true;
        }else{
            array[index]['showError'] = false;
        }
    }

    const handleAddPertanyaan = () => {
        let array = [...formPertanyaan.pilihan_obj]
        array.push({value : '', showError : false});
        setFormPertanyaan(formPertanyaan => ({...formPertanyaan, pilihan_obj: array}))
    }

    const handleDeleteJawaban = (index) => {
        let array = [...formPertanyaan.pilihan_obj]
        array.splice(index, 1);
        setFormPertanyaan(formPertanyaan => ({...formPertanyaan,
            pilihan_obj : array
        }))
    }

    return {
        alertValue, setAlertValue, handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal,
        isLoading, setIsLoading, campaigns, fetchCampaign,
        view, setView, listData, getTitle, totalWaiting, totalApproved, setTotalWaiting, setTotalApproved,
        bgColor, totalAll, handleChangeView, setCampaigns, handleSearch,
        handleShowApprove, selectedCampaign, handleCancel, initLang, setInitLang, campaignApproveLogs,
        handleApprove, handleSubmitApprove, handleChangeAlasan, handleBlurAlasan,
        handleClickMaps, formData, setFormData, showMarker, setShowMarker,
        handleDeleteLocation, handleCreate, handleSubmit, handleChangeVideo, handleChangePilihan,
        handleBlurPilihan, handleAddPertanyaan, handleDeleteJawaban, videoSrc, formPertanyaan, setFormPertanyaan,
        locations,
    }
}