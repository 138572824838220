import React, { useReducer  } from 'react';
import RoutersPage from '../views/Master/Routers/RoutersPage';
const UserContext = React.createContext([{}, () => { }]);


const UserProvider = (props) => {
    const modalReducer = (state, action) => {
        switch (action.type) {
            case 'OPEN':
                return {
                    isOpen: true,
                    text: action.text,
                    size: action.size,
                    content: action.content,
                    title: action.title,
                    onClose: action.onClose
                }
            case 'ROUTERS':
                return {
                    isOpen: true,
                    title: action.title,
                    content: <RoutersPage
                        // onClickTr={action.clickTr}
                        onClickTr={action.onClick}
                        isModal={true}
                        hideButtonEdit={action.hideButtonEdit || false}
                        hideButtonDelete={action.hideButtonDelete || false}
                    />,
                    onClose: action.onClose,
                    size: action.size,
                }
            case 'OTHERS':
                return {
                    isOpen: true,
                    title: action.title,
                    content:action.content,
                    onClose: action.onClose,
                    size: action.size,
                }
            case 'CLOSE':
                return {
                    isOpen: false,
                    text: '',
                    size: null,
                    content: null,
                    title: '',
                }
            default:
                return {
                    isOpen: false,
                    text: '',
                    size: 'lg',
                    content: null,
                    title: '',
                    onClose: null
                }
        }
    }

    const [modalState, modalDispatch] = useReducer(modalReducer, {
        isOpen: false,
        text: '',
        size: 'lg',
        content: null,
        title: '',
        onClose: null
    })

    return (
        <UserContext.Provider value={{
            modalState: [modalState, modalDispatch],
        }}>
            {props.children}
        </UserContext.Provider>
    )

}

export { UserContext, UserProvider };