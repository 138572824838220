import { useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import { DataContext } from "../../../context/DataContext";
import config from "../../../config";
import moment from 'moment';

export function useDeposite(){
    const {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, 
    } = useGlobalFunction();
    const { 
        saldoLogs, setSaldoLogs, fetchSaldoLogs, 
    } = useContext(DataContext)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    useEffect(() => {
        fetchSaldoLogs({variable: {
            'tipe_saldo' : 'debet',
            'order_by' : 'id',
            'order_type' : 'desc'
        }});
    },[fetchSaldoLogs])

    const paginateionSaldoLogs = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setSaldoLogs(saldoLogs => ({...saldoLogs,
            page : page, 
            offset: myOffset        
        }))
        
        fetchSaldoLogs({
            q: saldoLogs.search, 
            ofsset: myOffset, 
            config: config.itemPerPage,
            variable: {
                'tipe_saldo' : 'debet',
                'order_by' : 'id',
                'order_type' : 'desc'
            }
        });
    }

    const handleChangeDate = (e, name) => {
        let params = {}
        if (name === 'startDate'){
            setStartDate(e);
            params = {
                'tipe_saldo' : 'debet',
                'order_by' : 'id',
                'order_type' : 'desc',
                'start_date' : moment(e).format('YYYY-MM-DD'),                
            }
            if (endDate){
                params['end_date'] = moment(endDate).format('YYYY-MM-DD')
            }
        }else{
            setEndDate(e);
            params = {
                'tipe_saldo' : 'debet',
                'order_by' : 'id',
                'order_type' : 'desc',
                'end_date' : moment(e).format('YYYY-MM-DD'),                
            }
            if (startDate){
                params['start_date'] = moment(startDate).format('YYYY-MM-DD')
            }
        }
        fetchSaldoLogs({
            q : saldoLogs.search,
            offset : 0,
            limit : config.itemPerPage,
            variable: params
        })

    }

    const handleChangeSearch = (e) => {
        const {name, value } = e.target;
        setSaldoLogs(saldoLogs => ({...saldoLogs,
            [name] : value
        }))
    }

    const handleSearch = () => {
        let params = {
            'tipe_saldo' : 'debet',
            'order_by' : 'id',
            'order_type' : 'desc'
        }
        if (startDate){
            params['start_date'] = moment(startDate).format('YYYY-MM-DD')
        }
        if (endDate){
            params['end_date'] = moment(endDate).format('YYYY-MM-DD')
        }
        fetchSaldoLogs({
            q : saldoLogs.search,
            offset : 0,
            limit : config.itemPerPage,
            variable: params
        })
        setSaldoLogs(saldoLogs => ({...saldoLogs, offset: 0, page: 1}))
    }
    
    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    
    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, saldoLogs, paginateionSaldoLogs,
        handleChangeDate, handleKeyDownSearch, handleSearch, handleChangeSearch,
        startDate, endDate, setSaldoLogs
    }
}