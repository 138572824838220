import { DOTS, usePagination } from "../../hooks/usePagination";
import { TbChevronLeft, TbChevronRight, TbDots } from "react-icons/tb";
import { Button } from "..";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

/**
 *
 * @param {{
 * onPageChange: (page: number) => void;
 * totalCount: number;
 * siblingCount: number;
 * currentPage: number;
 * pageSize: number;
 * activeColor: "primary" | "base" | "success" | "warning" | "danger" | "info";
 * rounded: "none" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "full";
 * variant: "solid" | "flat";
 * size: "xs" | "sm" | "md" | "lg" | "xl";
 * className: string;
 * }} props
 *
 *
 */

const Pagination = ({
	onPageChange,
	totalCount,
	siblingCount = 1,
	currentPage,
	pageSize,
	activeColor = "primary",
	rounded,
	variant = "flat",
	size = "md",
	className
}) => {
	const { themeColor, colorMode } = useContext(ThemeContext);

	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	// Color
	const colorPagination =
		{
			primary: themeColor,
			base: "#BABCBD",
			success: "#4ED17E",
			warning: "#EEC239",
			danger: "#F26969",
			info: "#629BF8",
		}[activeColor] || activeColor;

	// Size
	const sizePagination =
		{
			xs: 25,
			sm: 30,
			md: 35,
			lg: 40,
			xl: 45,
		}[size] || size;

	let lastPage = paginationRange[paginationRange.length - 1];

	return (
		<div className={`flex gap-1 ${className}`}>
			{/* Left */}
			<Button
				size={sizePagination}
				className={`text-xs`}
				color={colorMode === "light" ? "#BABCBD95" : "#4D535595"}
				textcolor={`${colorMode === "light" ? "#171C1E" : "white"}`}
				variant={variant}
				rounded={rounded}
				onClick={onPrevious}
				disabled={currentPage === 1}
			>
				<TbChevronLeft />
			</Button>

			{paginationRange.map((pageNumber, index) => {
				
				if (pageNumber === DOTS) {
					return (
						<Button
							key={index}
							size={sizePagination}
							className="text-xs"
							color={colorMode === "light" ? "#BABCBD95" : "#4D535595"}
							textcolor={`${colorMode === "light" ? "#171C1E" : "white"}`}
							variant={variant}
							rounded={rounded}
							disabled
						>
							<TbDots />
						</Button>
					);
				}

				
				return (
					<Button
						key={index}
						size={sizePagination}
						className="text-xs"
						color={
							pageNumber === currentPage
								? colorPagination
								: colorMode === "light"
								? "#BABCBD95"
								: "#4D535595"
						}
						textcolor={
							pageNumber === currentPage
								? "white"
								: colorMode === "light"
								? "#171C1E"
								: "white"
						}
						variant={variant}
						rounded={rounded}
						onClick={() => onPageChange(pageNumber)}
					>
						{pageNumber}
					</Button>
				);
			})}

			{/* Right */}
			<Button
				size={sizePagination}
				className="text-xs"
				color={colorMode === "light" ? "#BABCBD95" : "#4D535595"}
				textcolor={`${colorMode === "light" ? "#171C1E" : "white"}`}
				variant={variant}
				rounded={rounded}
				disabled={currentPage === lastPage}
				onClick={onNext}
			>
				<TbChevronRight />
			</Button>
		</div>
	);
};

export default Pagination;
