import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";
import { HiOutlineMenu } from "react-icons/hi";
import {
	Avatar,
	Badge,
	Button,
	ButtonDarkMode,
	ButtonRipple,
	Popover,
	Tooltip,
} from "..";
import { TbBell } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const Header = ({ setSideOpen }) => {
	const { themeSkin, navbarType } = useContext(ThemeContext);

	const navigate = useNavigate();


	return (
		
		<header
			className={`bg-base-50/30 dark:bg-neutral-900/10 backdrop-blur-sm h-20 px-6 pt-4 pb-0 top-0 w-full z-30   ${navbarType} invisible md:visible h-0 md:h-20 hidden md:flex md:flex-col`}
		>
			<div
				className={`w-full h-full flex justify-between items-center px-6 bg-white/80 dark:bg-base-600/80 backdrop-blur-sm rounded-md ${
					themeSkin === "default" ? "shadow-lg" : themeSkin
				}`}
			>
				<div>
					<div
						onClick={() => setSideOpen(true)}
						className="cursor-pointer block lg:hidden"
					>
						<HiOutlineMenu className="text-2xl" />
					</div>
				</div>

				<div className="flex items-center gap-4">
					<div className="flex items-center">
						{/* Dark Mode */}
						<Tooltip tooltip="Dark Mode" delay={500}>
							<ButtonDarkMode />
						</Tooltip>

						{/* Notification */}
						<Popover
							placement="bottom-end"
							spacing={20}
							button={
								<ButtonRipple className="p-2 rounded-full transition-[background] hover:bg-neutral-200 dark:hover:bg-base-500">
									<Badge
										hidden
										size="xl"
										placement="top-end"
										skidding={8}
										color="success"
									>
										<div className="text-2xl">
											<TbBell />
										</div>
									</Badge>
								</ButtonRipple>
							}
						>
							<div className="text-sm min-w-[200px] rounded-lg">
								<button className="p-4 py-2 hover:bg-slate-500 rounded cusrp">
									Menu 1
								</button>
							</div>
						</Popover>
					</div>

					{/* Profile */}
					<Popover
						placement="bottom-end"
						spacing={20}
						button={
							<ButtonRipple className="rounded-full">
								<Badge size="sm" placement="right-end" color="success">
									<Avatar color="primary">AD</Avatar>
								</Badge>
							</ButtonRipple>
						}
					>
						<div className="text-sm min-w-[200px] rounded-lg p-2">
							<Button
								onClick={() => navigate("/login")}
								variant="text"
								block
								color="danger"
							>
								Logout
							</Button>
						</div>
					</Popover>
				</div>
			</div>
		</header>		
	);
};

export default Header;
