import { DataProvider } from "./context/DataContext";
import { ThemeProvider } from "./context/ThemeContext";
import Router from "./router";

const App = () => {
	return (
		<ThemeProvider>
			<DataProvider>
				<Router />
			</DataProvider>
		</ThemeProvider>
	);
};

export default App;
