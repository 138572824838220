import { useState, useCallback, useEffect } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import api from "../../../api";
import config from "../../../config";
import { pageObj } from "../../../generalFunction/pageObj";

export function usePaymentGateway(props){
    const {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, 
    } = useGlobalFunction();
    const [listData, setListData] = useState([]);
    const [view, setView] = useState('list')
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
		id : {
			name : 'id',
			label : 'ID',
			value : '',
			type : 'text',
			col : 2,
			required : false,
			show : false,
			errorMsg : '',
			showError : false,
			flexType : 'column',
		},		
		key : {
			name : 'key',
			label : 'Key',
			value : '',
			type : 'select',
			col : 2,
			required : true,
			show : true,
			errorMsg : '',
			showError : false,
			flexType : 'column',
            obj : [
                {value : '', label : '-- Pilih Key --'},
                {value : 'bca_va', label : 'BCA VA'},
                {value : 'echannel', label : 'Mandiri VA'},
                {value : 'bni_va', label : 'BNI VA'},
                {value : 'permata', label : 'Permata VA'},
                {value : 'bri_va', label : 'BRI VA'},
                {value : 'gopay', label : 'GOPAY'},
                {value : 'alfamart', label : 'Alfamart'},
                {value : 'indomaret', label : 'Indomart'},
                {value : 'bca_klikpay', label : 'BCA Klikpay'},
            ]
		},
		nama : {
			name : 'nama',
			label : 'Nama',
			value : '',
			type : 'text',
			col : 2,
			required : false,
			show : true,
			errorMsg : '',
			showError : false,
			flexType : 'column',            
		},
		icon : {
			name : 'icon',
			label : 'Icon',
			value : '',
			type : 'image',
			col : 2,
			required : false,
			show : true,
			errorMsg : '',
			showError : false,
			flexType : 'column',            
		},
		tipe : {
			name : 'tipe',
			label : 'Tipe',
			value : false,
			type : 'select',
			col : 2,
			required : false,
			show : true,
			errorMsg : '',
			showError : false,
			flexType : 'column',   
            obj : [
                {value : '', label : '-- Pilih Tipe --'},
                {value : 'wallet', label : 'Wallet'},
                {value : 'va', label : 'VA'},
                {value : 'merchant', label : 'Merchant'},
            ]         
		},
		metode_pembayaran : {
			name : 'metode_pembayaran',
			label : 'Metode Pembayaran',
			value : 2,
			type : 'text',
			col : 2,
			required : false,
			show : true,
			errorMsg : '',
			showError : false,
			flexType : 'column',
            thousandSeparator : true
		},
	})

    const fetchData = useCallback(async(q="", offset=0, limit=config.itemPerPage) => {
		setIsLoading(true);
		try{
			let params = {
				offset : offset,
				limit : limit,
			}
			if (q) {
				params['q'] = q;
			}
			const res = await api.get(`${config.endPoint.paymentGatewayMethod}`, {params : params}).then(res => res.data);
            
			if (res){                
				setTimeout(() => {
					setListData(res.results);
					setIsLoading(false);
				}, 500)
				setPageValue(pageValue => ({...pageValue,
					obj : pageObj(res.count, limit, offset),
					lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
				}))
			}else{
				setIsLoading(false);
			}
		}catch(error){
			setIsLoading(false);
			const { response} = error;
			let msg = "Unable to fetch data"
			if (response && response.data && response.data.message){
				msg = response.data.message;
			}
			setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
			setTimeout(() => {
				setAlertValue(alertValue => ({...alertValue, show: false}))
			}, config.timeOutValue)
		}
	},[setIsLoading, setAlertValue, setPageValue])

	useEffect(() => {
		fetchData();
	},[fetchData])

	const handleChangeSearch = (e) => {
		const { name, value } = e.target;
		setPageValue(pageValue => ({...pageValue, [name] : value}))
	}

	const handleSearch = () => {
		fetchData(pageValue.search, 0, config.itemPerPage);
		setPageValue(pageValue => ({...pageValue, page: 1, offset : 0}))
	}

	const handleKeyDownSearch = (e) => {
		if (e.key === 'Enter'){            
			handleSearch();
		}
	}

	const handlePaginate = (page) => {
		let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
		setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
		fetchData(pageValue.search, myOffset, config.itemPerPage, pageValue.cabang_id);
	}

	const handleCreate = () => {
		setView('create');
		setIsEdit(false);
        setFormData(formData => ({...formData,
			id : {...formData.id, value : ''},
            key : {...formData.key, value : ''},
            nama : {...formData.nama, value : ''},
            icon : {...formData.icon, value : ''},
            tipe : {...formData.tipe, value : ''},
		}))
	}

	const handleEdit = (params) => {
		setView('create');
		setIsEdit(true);
		setFormData(formData => ({...formData,
			id : {...formData.id, value : params.id},			
			key : {...formData.key, value : params.key},
            nama : {...formData.nama, value : params.nama},
            icon : {...formData.icon, value : params.icon},
            tipe : {...formData.tipe, value : params.tipe},            
		}))
	}

	const handleCancel = () => {
		setView('list');
	}

	const handleSubmit = async() => {
		setIsLoading(true);
		try{
			let obj = {...formData};
			let tmp = {}
			let countError = 0;

			Object.entries(obj).map(([index, post]) => {
				if (post.required && post.value === ''){
					post['showError'] = true;
					countError++;
				}else{
					post['showError'] = false;
					tmp[index] = post.value;
				}
				return true;
			})

			if (countError > 0){
				setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
				setTimeout(() => {
					setAlertValue(alertValue => ({...alertValue, show: false}))
				}, config.timeOutValue)
				setFormData(obj);
				setIsLoading(false);
				return;
			}
            

			let newForm = new FormData();
			newForm.append('formData', JSON.stringify(tmp));
			let res;
			if (isEdit){
				res = await api.put(`${config.endPoint.paymentGatewayMethod}${formData.id.value}/`, newForm).then(res => res.data);
			}else{
				res = await api.post(`${config.endPoint.paymentGatewayMethod}`, newForm).then(res => res.data);
			}
			
			if (res){
				setAlertValue(alertValue => ({...alertValue, show: true, text : "Data tersimpan", color : "success"}));
				setTimeout(() => {
					setAlertValue(alertValue => ({...alertValue, show: false}))
				}, config.timeOutValue);
				setView('list')
				setIsLoading(false)
				fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
			}
		}catch(error){
			setIsLoading(false);
			const { response} = error;
			let msg = "Unable to fetch data"
			if (response && response.data && response.data.message){
				msg = response.data.message;
			}
			setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
			setTimeout(() => {
				setAlertValue(alertValue => ({...alertValue, show: false}))
			}, config.timeOutValue)
		}
	}
	
	const handleDelete = (params) => {
		setModalValue(modalValue => ({...modalValue, 
			show: true, 
			title : 'Konfirmasi', 
			id : params.id, 
			text : `Apakah anda yakin akan menghapus payment gateway ${params.nama} ?`
		}))
	}
	
	const handleSubmitDelete = async() => {
		setIsLoading(true);
		try{
			const res = await api.delete(`${config.endPoint.paymentGatewayMethod}${modalValue.id}/`).then(res => res.data);
			if (res){
				handleCloseModal();
				setAlertValue(alertValue => ({...alertValue, show: true, text : 'Data Terhapus', color : 'success'}));
				setTimeout(() => {
					setAlertValue(alertValue => ({...alertValue, show: false}));
				}, config.timeOutValue);
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
			}
			setIsLoading(false);
		}catch(error){
			setIsLoading(false);
			setAlertValue(alertValue => ({...alertValue, show: true, text : 'unable to delete data', color : 'danger'}));
			setTimeout(() => {
				setAlertValue(alertValue => ({...alertValue, show: false}));
			}, config.timeOutValue)
		}
	}

	// =============== form data =============================
	const handleChange = (e) => {        
        const { name, value, type, checked } = e.target;
        
        if (type === 'checkbox'){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], value : checked}
            }))
        }else{
            let nilai = value;
            if (formData[name].tipe === 'number'){
                nilai = nilai.replace(/,/g, '');
            }
            setFormData(formData => ({...formData,
                [name] : {...formData[name], value : nilai}
            }))            
        }
    }
        
    const handleBlur = (e) => {
        const {name, value} = e.target;
        if (formData[name].required && value === ''){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : true}
            }))
        }else{
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : false}
            }))
        }
    }

    // =============== icon ============
    const handleUploadFoto = (index) => {
        document.getElementById(`upload-button`).click()
    }

    const handleInsertFoto = (e) => {
        var nfile = e.target.files[0];        
        var reader = new FileReader();
        reader.readAsDataURL(nfile);
        reader.onloadend = function() {
            setFormData(formData => ({...formData, icon : {...formData.icon, value : reader.result}}))
        }
	}

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, 
        formData, setFormData, view, listData, isEdit,
        handleChangeSearch, handleSearch, handleKeyDownSearch,
        handlePaginate, handleCreate, handleEdit, handleCancel, handleSubmit,
        handleDelete, handleSubmitDelete, handleChange,  
        handleBlur, handleUploadFoto, handleInsertFoto,
    }
}