import { useContext, useEffect, useState, 
    // useState 
} from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import { DataContext } from "../../../context/DataContext";
import { ThemeContext } from "../../../context/ThemeContext";
import config from "../../../config";

export function useClient(props){
    const {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, bgColor
    } = useGlobalFunction();
    const [kontakSelected, setKontakSelected] = useState(null);
    const [view, setView] = useState('list');
    const { themeColor } = useContext(ThemeContext)
    
    
    const { 
        kontaks, setKontaks, fetchKontak, paginateKontak,
        fetchCampaignLogs, campaignLogs, fetchCampaign, campaigns,
        saldoLogs, fetchSaldoLogs, setSaldoLogs, setCampaignLogs
    } = useContext(DataContext);

    useEffect(() => {
        fetchKontak({variable: {tipe:'is_advertiser'}})
    },[fetchKontak])


    const handleSearch = () => {
        fetchKontak({
            q : kontaks.search, 
            offset : kontaks.offset, 
            limit : config.itemPerPage, 
            variable : {tipe: 'is_advertiser'}, 
            tipe_fetching: 'update'
        })
    }

    const handleDetail = (post) => {
        setView('detail');
        setKontakSelected(post);
        fetchCampaignLogs({
            variable:{
                owner_id: post.id,
            }, 
            tipe_fetching:'update'
        })
        fetchCampaign({
            variable:{
                owner_id: post.id,
            },
            tipe_fetching:'update'
        })
        fetchSaldoLogs({
            variable:{
                kontak_id: post.id,
            },
            tipe_fetching:'update'
        })        
    }

    const paginateSaldoLogs = (page) => {
        
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setSaldoLogs(saldoLogs => ({...saldoLogs,
            page : page, 
            offset: myOffset        
        }))
        
        fetchSaldoLogs({
            q:saldoLogs.search, 
            offset: myOffset, 
            limit: config.itemPerPage,
            variable: {kontak_id: kontakSelected.id},
            tipe_fetching:'update',
        });        
    }

    const paginateCampaignLogs = (page) => {
        
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setCampaignLogs(campaignLogs => ({...campaignLogs,            
            page : page,
            offset: myOffset,             
        }))
                
        fetchCampaignLogs({
            q:saldoLogs.search, 
            offset: myOffset, 
            limit: config.itemPerPage,
            variable: {owner_id: kontakSelected.id},
            tipe_fetching:'update',
        });     
    }
    const handleKembali = () => {
        setView('list')
    }

    
    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, bgColor,
        kontaks, setKontaks, paginateKontak, themeColor, handleSearch, handleDetail, 
        view, kontakSelected, campaignLogs, campaigns, paginateCampaignLogs,
        saldoLogs, paginateSaldoLogs, handleKembali
    }
}