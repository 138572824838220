import { Fragment } from "react";
import { Loading, Pagination, Tables } from "../../../components";
import AlertComp from "../../../components/layout/AlertComp";
import Card from "../../../components/layout/Card";
import config from "../../../config";
import { useIklanTayang } from "./iklanTayangFunction";



const IklanTayangPage = (props) => {
    const { alertValue, handleCloseAlert, campaignLogs, paginateCampaignLogs } = useIklanTayang();
    return(
        <div className='h-full w-full'>
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                subText={alertValue.subText}
                icon={alertValue.icon}
            />
            <Card title="History Akses" isSearch={false} className="mt-5">
                { campaignLogs.isLoading ?
                    <div>
                        <Loading variant={'skeleton'} loadingClassName='h-[30px] w-full' />
                        <div className="flex w-full gap-4">
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                        </div>
                        <div className="flex w-full gap-4">
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                        </div>
                    </div>
                    :
                    <Fragment>
                        <Tables>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                    <Tables.Header className='text-[13px] text-right'>#</Tables.Header>
                                    <Tables.Header className='text-[13px]'>Campaign</Tables.Header>
                                    <Tables.Header className='text-[13px]'>Owner</Tables.Header>
                                    <Tables.Header className='text-[13px]'>Lokasi Akses</Tables.Header>
                                    <Tables.Header className='text-[13px]'>Waktu</Tables.Header>                                                
                                    <Tables.Header className='text-[13px] text-right'>Biaya</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                {campaignLogs && campaignLogs.listData && campaignLogs.listData.map((post, index) => {
                                    return (
                                        <Tables.Row key={index}>
                                            <Tables.Data className='text-right'>{index + 1 + campaignLogs.offset}</Tables.Data>
                                            <Tables.Data>{post.campaign_display}</Tables.Data>
                                            <Tables.Data>{post.owner_display}</Tables.Data>
                                            <Tables.Data>
                                                <div>Latitude : {post.lat_lon_display && post.lat_lon_display.lat}</div>
                                                <div>longitude : {post.lat_lon_display && post.lat_lon_display.lon}</div>
                                            </Tables.Data>
                                            <Tables.Data>
                                                { post.tanggal_display && post.tanggal_display.datetime_display}
                                            </Tables.Data>
                                            <Tables.Data className='text-right'>
                                                { post.biaya_display && post.biaya_display.toLocaleString()}
                                            </Tables.Data>
                                        </Tables.Row>
                                    )
                                })}
                            </Tables.Body>
                        </Tables>
                        <div className="flex justify-end">
                            <Pagination
                                totalCount={campaignLogs.totalRecords}
                                onPageChange={(page) => {
                                    paginateCampaignLogs(page);
                                }}
                                currentPage={campaignLogs.page}
                                pageSize={config.itemPerPage}
                                className="mt-5 text-right"
                            />
                        </div>
                    </Fragment>
                }
            </Card>
        </div>
    )
}
export default IklanTayangPage;