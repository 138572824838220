import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import config from '../config';

const MapComp = (props) => {
    return(
        <Map 
            google={props.google} 
            zoom={props.zoom}
            initialCenter={{
                lat: props.init_lat,
                lng: props.init_lng
            }}
            style={props.style}
            onRightclick = {props.onRightclick}
            onDragend={props.onDragend}
            onClick={props.onClick}
            containerStyle={props.containerStyle}
        >                                
            { props.showMarker &&
                <Marker 
                    position={{
                        lat: props.lat,
                        lng: props.lng
                    }}                                    
                />
            }
            { props.children }            
        </Map>
    )
}

export default GoogleApiWrapper({
    apiKey: config.googleMapKey
   }) (MapComp);

// export default GoogleApiWrapper({
//     apiKey: ('AIzaSyDBtc5KO5NuaWyhGuEA50Kfo06bhZ9s7K8')
//    }) (MapComp);
