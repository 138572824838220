import { useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import { DataContext } from "../../../context/DataContext";
import { UserContext } from "../../../context/UserContext";
import api from "../../../api";
import config from "../../../config";
import RestoForm from "./RestoForm";

export function useResto(){
    const { 
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, bgStatusResto
    } = useGlobalFunction();
    const { 
        resto, setResto, fetchResto, paginateResto,        
    } = useContext(DataContext)
    const myState = useContext(UserContext)
    const [modalState, modalDispatch] = myState.modalState
    const [view, setView] = useState('list')

    useEffect(() => {
        fetchResto({});
    },[fetchResto])

    const handleSearch = () => {

    }

    const handleShowRouter = (params) => {
        modalDispatch({
            'type': 'ROUTERS',
            onClick : (post) => handleClickRouters(post, params),
            'onClose' : closeModalHandler(),   
            'hideButtonEdit' : true,
            'hideButtonDelete' : true,         
        })
    }

    const closeModalHandler = () => {
        modalDispatch({type : 'CLOSE'})
    }

    const handleClickRouters = async(params, old_value) => {
        setIsLoading(true);
        try{
            let obj = {...old_value}
            delete obj['logo']
            // console.log('old_value >>>>', obj)
            obj['router'] = params.id

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(obj));
            
            const res = await api.put(`${config.endPoint.resto}${old_value.id}/`, newForm).then(res => res.data);
            if (res){
                closeModalHandler();
            }
            console.log('res is >>>>>', res);
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
        }
    }

    const handleAktif = (params) => {
        modalDispatch({
            type: 'OTHERS',
            onClose : closeModalHandler,
            content : <RestoForm 
                data={params} 
                readOnly={true} 
                handleCancel={() => closeModalHandler()}
                handleApprove={() => handleApprove(params)}
                isLoading={isLoading}
                butonText={[1,2,4].includes(params.state) ? 'Approve' : 'Terminate'}
            />
            
        })
    }

    const handleApprove = async(params) => {
        setIsLoading(true);
        try{
            let tmp = {...params};
            if ([1,2,4].includes(params.state)){
                tmp['state'] = 3
            }else if([3].includes(params.state)){
                tmp['state'] = 4
            }

            delete tmp['logo']

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));

            const res = await api.put(`${config.endPoint.resto}${tmp.id}/`, newForm).then(res => res.data);
            if (res){
                closeModalHandler();
                fetchResto({q:resto.search, limit : config.itemPerPage, offset: resto.offset})
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
        }
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, setView,
        resto, setResto,  paginateResto, view, handleSearch, handleShowRouter, modalState,
        closeModalHandler, bgStatusResto, handleAktif
    }

}