import { Fragment } from "react";
import { Loading, Pagination, Tables } from "../../../components";
import AlertComp from "../../../components/layout/AlertComp";
import Card from "../../../components/layout/Card";
import { useDeposite } from "./depositeFunction";
import config from "../../../config";
import FormTextSingleComp from "../../../components/FormTextSingleComp";
import { Label } from "../../../components/forms";
import Search from "../../../components/atoms/Search";

const DepositePage = (props) => {
    const { alertValue, handleCloseAlert, saldoLogs, paginateionSaldoLogs,
        handleChangeDate,  handleSearch, 
        startDate, setSaldoLogs, endDate
    } = useDeposite();
    return(
        <div className='h-full w-full'>
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                subText={alertValue.subText}
                icon={alertValue.icon}
            />
            <Card title="Topup Client" isSearch={false} className="mt-5">
                <div className="grid grid-cols-12 gap-4 mb-3">
                    <div className="col-span-6 w-full">
                        <div className='text-sm font-medium  text-gray-700 mb-[12px]'>Cari</div>
                        <Search
                            className='mt-2'
                            placeholder={"search"}
                            onChange={(e) => setSaldoLogs(saldoLogs => ({...saldoLogs, search: e}))}
                            handleSearch={handleSearch}
                            value={saldoLogs.search}
                            inputClassName={'py-2'}
                        />
                    </div>
                    <div className="col-span-6 w-full">
                        <div className="flex flex-row justify-end gap-4">
                            <div>
                                <Label>Filter Dari Tanggal</Label>
                                <FormTextSingleComp
                                    name="startDate"
                                    value={startDate}
                                    onChangeDate={(e, name) => handleChangeDate(e, name)}                            
                                    type="date"
                                    show={true}
                                    required={false}
                                    flexType={"column"}
                                    hideLabel={true}
                                />
                            </div>
                            <div>
                                <Label>s.d Tanggal</Label>
                                <FormTextSingleComp
                                    name="endDate"
                                    value={endDate}
                                    onChangeDate={(e, name) => handleChangeDate(e, name)}                            
                                    type="date"
                                    show={true}
                                    required={false}
                                    flexType={"column"}
                                    hideLabel={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                { saldoLogs.isLoading ?
                    <div>
                        <Loading variant={'skeleton'} loadingClassName='h-[30px] w-full' />
                        <div className="flex w-full gap-4">
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                        </div>
                        <div className="flex w-full gap-4">
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                        </div>
                    </div>
                    :
                    <Fragment>
                        <Tables>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Client</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Tanggal</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Keterangan</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>Jumlah</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Method</Tables.Header>                                    
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                {saldoLogs && saldoLogs.listData && saldoLogs.listData.map((post, index) => {
                                    return (
                                        <Tables.Row key={index}>
                                            <Tables.Data className='text-right'>{saldoLogs.offset+index+1}</Tables.Data>                                                
                                            <Tables.Data>{post.kontak_display}</Tables.Data>
                                            <Tables.Data>
                                                <div>{post.tanggal_display.tanggal}</div>
                                                <div className='text-[12px]'>{post.tanggal_display.jam}</div>
                                            </Tables.Data>
                                            <Tables.Data>{post.keterangan_display.text}</Tables.Data>
                                            <Tables.Data className='text-right'>{post.ammdb.toLocaleString()}</Tables.Data>
                                            <Tables.Data>{post.method_display && post.method_display.method_display}</Tables.Data>
                                            
                                        </Tables.Row>
                                    )
                                })}
                            </Tables.Body>
                        </Tables>
                        <div className="flex justify-end">
                            <Pagination
                                totalCount={saldoLogs.totalRecords}
                                onPageChange={(page) => {
                                    paginateionSaldoLogs(page);
                                }}
                                currentPage={saldoLogs.page}
                                pageSize={config.itemPerPage}
                                className="mt-5 text-right"
                            />
                        </div>
                    </Fragment>
                }
            </Card>
        </div>
    )
}

export default DepositePage;