import { Fragment } from "react"
import { useClient } from "./clientFunction";
import AlertComp from "../../../components/layout/AlertComp";
import Card from "../../../components/layout/Card";
import { Button, ButtonRipple, Loading, Pagination, Tables, Tooltip } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import config from "../../../config";

const ClientPage = (props) => {
    const { 
        // isLoading, themeColor, 
        alertValue, handleCloseAlert,
        kontaks, setKontaks, handleSearch, themeColor,
        handleDetail, view, kontakSelected, campaigns,
        campaignLogs, paginateCampaignLogs,
        saldoLogs, paginateSaldoLogs,
        handleKembali
    } = useClient();

    const approveColor = (params) => {
        if (params === 1){
            return 'bg-blue-100 text-blue-700'
        }else if (params === 3){
            return 'bg-red-100 text-red-700'
        }else{
            return 'bg-green-100 text-green-700'

        }
    }
    
    return(
        <div className='h-full w-full'>
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                subText={alertValue.subText}
                icon={alertValue.icon}
            />
            { view === 'detail' &&
                <div className="flex flex-row justify-end">
                    <Button
                        variant="solid"
                        onClick={handleKembali}
                        color="primary"
                        className="mb-2"
                    >
                        Kembali
                    </Button>
                </div>
            }
            { view === 'detail' &&
                <div className='grid grid-cols-2 gap-4'>
                    <div className='col-span-2 md:col-span-1'>
                        <Card
                            title={`Client ${kontakSelected && kontakSelected.nama}`}
                            isSearch={false}
                        >
                            <ul className='text-[14px]'>
                                <li className='grid grid-cols-12 gap-4'>
                                    <div className='col-span-4'>Nama</div>
                                    <div className='col-span-8'>: {kontakSelected && kontakSelected.nama}</div>
                                </li>
                                <li className='grid grid-cols-12 gap-4'>
                                    <div className='col-span-4'>Email</div>
                                    <div className='col-span-8'>: {kontakSelected && kontakSelected.email}</div>
                                </li>
                                <li className='grid grid-cols-12 gap-4'>
                                    <div className='col-span-4'>Tanggal Daftar</div>
                                    <div className='col-span-8'>: {kontakSelected && kontakSelected.tanggal_daftar_display}</div>
                                </li>
                                <li className='grid grid-cols-12 gap-4'>
                                    <div className='col-span-4'>Jumlah Iklan</div>
                                    <div className='col-span-8'>: {kontakSelected && kontakSelected.jumlah_iklan_display}</div>
                                </li>
                                <li className='grid grid-cols-12 gap-4'>
                                    <div className='col-span-4'>Saldo</div>
                                    <div className='col-span-8'>: Rp.{kontakSelected && kontakSelected.saldo_display && kontakSelected.saldo_display.saldo.toLocaleString()}</div>
                                </li>
                                <li className='grid grid-cols-12 gap-4'>
                                    <div className='col-span-4'>Total Topup</div>
                                    <div className='col-span-8'>: Rp.{kontakSelected && kontakSelected.saldo_display && kontakSelected.saldo_display.debet.toLocaleString()}</div>
                                </li>
                                <li className='grid grid-cols-12 gap-4'>
                                    <div className='col-span-4'>Total Anggaran Terpakai</div>
                                    <div className='col-span-8'>: Rp.{kontakSelected && kontakSelected.saldo_display && kontakSelected.saldo_display.kredit.toLocaleString()}</div>
                                </li>
                            </ul>
                        </Card>
                        <Card title="History Akses" isSearch={false} className="mt-5">
                            { campaignLogs.isLoading ?
                                <div>
                                    <Loading variant={'skeleton'} loadingClassName='h-[30px] w-full' />
                                    <div className="flex w-full gap-4">
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    </div>
                                    <div className="flex w-full gap-4">
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    </div>
                                </div>
                                :
                                <Fragment>
                                    <Tables>
                                        <Tables.Head className='bg-[#efeeee]'>
                                            <tr>
                                                <Tables.Header className='text-[13px] text-right'>#</Tables.Header>
                                                <Tables.Header className='text-[13px]'>Campaign</Tables.Header>
                                                <Tables.Header className='text-[13px]'>Lokasi Akses</Tables.Header>
                                                <Tables.Header className='text-[13px]'>Waktu</Tables.Header>                                                
                                            </tr>
                                        </Tables.Head>
                                        <Tables.Body>
                                            {campaignLogs && campaignLogs.listData && campaignLogs.listData.map((post, index) => {
                                                return (
                                                    <Tables.Row key={index}>
                                                        <Tables.Data>{index + 1 + campaignLogs.offset}</Tables.Data>
                                                        <Tables.Data>{post.campaign_display}</Tables.Data>
                                                        <Tables.Data>
                                                            <div>Latitude : {post.lat_lon_display && post.lat_lon_display.lat}</div>
                                                            <div>longitude : {post.lat_lon_display && post.lat_lon_display.lon}</div>
                                                        </Tables.Data>
                                                        <Tables.Data>
                                                            { post.tanggal_display && post.tanggal_display.datetime_display}
                                                        </Tables.Data>
                                                    </Tables.Row>
                                                )
                                            })}
                                        </Tables.Body>
                                    </Tables>
                                    <div className="flex justify-end">
                                        <Pagination
                                            totalCount={campaignLogs.totalRecords}
                                            onPageChange={(page) => {
                                                paginateCampaignLogs(page);
                                            }}
                                            currentPage={campaignLogs.page}
                                            pageSize={config.itemPerPage}
                                            className="mt-5 text-right"
                                        />
                                    </div>
                                </Fragment>
                            }
                        </Card>
                    </div>
                    <div className='col-span-2 md:col-span-1'>
                        <Card
                            title="Iklan"
                            isSearch={false}
                        >
                            {campaigns.isLoading ?
                                <div>
                                    <Loading variant={'skeleton'} loadingClassName='h-[30px] w-full' />
                                    <div className="flex w-full gap-4">
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    </div>
                                    <div className="flex w-full gap-4">
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    </div>
                                </div>
                                :
                                <Fragment>
                                    <Tables>
                                        <Tables.Head className='bg-[#efeeee]'>
                                            <tr>
                                                <Tables.Header className='text-[13px] text-right'>#</Tables.Header>
                                                <Tables.Header className='text-[13px]'>Campaign</Tables.Header>
                                                <Tables.Header className='text-[13px]'>Owner</Tables.Header>
                                                <Tables.Header className='text-[13px]'>Approved</Tables.Header>
                                                <Tables.Header className='text-[13px]'>Tanggal Tayang</Tables.Header>
                                                <Tables.Header className='text-[13px]'>Jam Tayang</Tables.Header>
                                                <Tables.Header className='text-[13px] text-right'>Anggaran</Tables.Header>
                                                <Tables.Header className='text-[13px] text-right'>Anggaran Terpakai</Tables.Header>
                                            </tr>
                                        </Tables.Head>
                                        <Tables.Body>
                                            {campaigns && campaigns.listData && campaigns.listData.map((post, index) => {
                                                return (
                                                    <Tables.Row key={index}>
                                                        <Tables.Data>{index + 1 + campaigns.offset}</Tables.Data>
                                                        <Tables.Data>{post.nama}</Tables.Data>
                                                        <Tables.Data>{post.owner_display}</Tables.Data>
                                                        <Tables.Data>
                                                            <div className={`text-center text-[12px] ${approveColor(post.approved_state_display.approve_state)} px-[8px] py-[2px] w-fit rounded-full`}>
                                                                {post.approved_state_display.state_display}
                                                            </div>
                                                        </Tables.Data>
                                                        <Tables.Data>
                                                            {post.startend_date_display.start_date_display} s.d {post.startend_date_display.end_date_display}
                                                        </Tables.Data>
                                                        <Tables.Data>
                                                            {post.startend_date_display.start_time_display} s.d {post.startend_date_display.end_time_display}
                                                        </Tables.Data>
                                                        <Tables.Data className='text-right'>
                                                            Rp.{post.anggaran.toLocaleString()}
                                                        </Tables.Data>
                                                        <Tables.Data className='text-right'>
                                                            Rp.{post.anggaran_terpakai.toLocaleString()}
                                                        </Tables.Data>
                                                    </Tables.Row>
                                                )
                                            })}
                                        </Tables.Body>
                                    </Tables>
                                </Fragment>
                            }
                        </Card>

                        <Card title="History Saldo" isSearch={false} className="mt-5">
                            { saldoLogs.isLoading ?
                                <div>
                                    <Loading variant={'skeleton'} loadingClassName='h-[30px] w-full' />
                                    <div className="flex w-full gap-4">
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    </div>
                                    <div className="flex w-full gap-4">
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                        <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                    </div>
                                </div>
                                :
                                <Fragment>
                                    <Tables>
                                        <Tables.Head className='bg-[#efeeee]'>
                                            <tr>
                                                <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Tanggal</Tables.Header>
                                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Keterangan</Tables.Header>
                                                <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>Debet</Tables.Header>
                                                <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>Kredit</Tables.Header>
                                                <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>Saldo</Tables.Header>
                                            </tr>
                                        </Tables.Head>
                                        <Tables.Body>
                                            {saldoLogs && saldoLogs.listData && saldoLogs.listData.map((post, index) => {
                                                return (
                                                    <Tables.Row key={index}>
                                                        <Tables.Data className='text-right'>{saldoLogs.offset+index+1}</Tables.Data>                                                
                                                        <Tables.Data>
                                                            <div>{post.tanggal_display.tanggal}</div>
                                                            <div>{post.tanggal_display.jam}</div>
                                                        </Tables.Data>
                                                        <Tables.Data>{post.keterangan_display.text}</Tables.Data>
                                                        <Tables.Data className='text-right'>{post.ammdb.toLocaleString()}</Tables.Data>
                                                        <Tables.Data className='text-right'>{post.ammcr.toLocaleString()}</Tables.Data>
                                                        <Tables.Data className='text-right'>{post.saldo.toLocaleString()}</Tables.Data>
                                                    </Tables.Row>
                                                )
                                            })}
                                        </Tables.Body>
                                    </Tables>
                                    <div className="flex justify-end">
                                        <Pagination
                                            totalCount={saldoLogs.totalRecords}
                                            onPageChange={(page) => {
                                                paginateSaldoLogs(page);
                                            }}
                                            currentPage={saldoLogs.page}
                                            pageSize={config.itemPerPage}
                                            className="mt-5 text-right"
                                        />
                                    </div>
                                </Fragment>
                            }
                        </Card>
                    </div>
                </div>
            }
            { view === 'list' &&
                <Card
                    title={"Client"}
                    isSearch={true}
                    onChangeSearch={(value) => setKontaks(kontaks => ({
                        ...kontaks,
                        search: value
                    }))}
                    searchValue={kontaks.search}
                    handleSearch={handleSearch}
                >                
                    {kontaks.isLoading ?
                        <div>
                            <Loading variant={'skeleton'} loadingClassName='h-[30px] w-full' />
                            <div className="flex w-full gap-4">
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            </div>
                            <div className="flex w-full gap-4">
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                                <Loading variant={'skeleton'} loadingClassName='h-2.5 w-full' className={'w-full'} />
                            </div>
                        </div>
                        :
                        <Fragment>
                            <Tables>
                                <Tables.Head className='bg-[#efeeee]'>
                                    <tr>
                                        <Tables.Header className='text-[13px] text-right'>#</Tables.Header>
                                        <Tables.Header className='text-[13px]'>Nama</Tables.Header>
                                        <Tables.Header className='text-[13px]'>Email</Tables.Header>
                                        <Tables.Header className='text-[13px]'>Tanggal Daftar</Tables.Header>
                                        <Tables.Header className='text-[13px] text-right'>Jumlah Iklan</Tables.Header>
                                        <Tables.Header className='text-[13px] text-right'>Saldo</Tables.Header>
                                        <Tables.Header className='text-[13px] text-right'>Transaksi In</Tables.Header>
                                        <Tables.Header className='text-[13px] text-right'>Transaksi Out</Tables.Header>                                    
                                        <Tables.Header className='text-[13px] text-center'>Action</Tables.Header>
                                    </tr>
                                </Tables.Head>
                                <Tables.Body>
                                    {kontaks && kontaks.listData.map((post, index) => {
                                        return (
                                            <Tables.Row key={index}>
                                                <Tables.Data className="text-right">{index + 1 + kontaks.offset}</Tables.Data>
                                                <Tables.Data>{post.nama}</Tables.Data>
                                                <Tables.Data>{post.email}</Tables.Data>
                                                <Tables.Data>{post.tanggal_daftar_display}</Tables.Data>
                                                <Tables.Data className='text-right'>{post.jumlah_iklan_display}</Tables.Data>
                                                <Tables.Data className='text-right'>
                                                    Rp.{post.saldo_display && post.saldo_display.saldo && post.saldo_display.saldo.toLocaleString()}
                                                </Tables.Data>
                                                <Tables.Data className='text-right'>
                                                    Rp.{post.saldo_display && post.saldo_display.debet && post.saldo_display.debet.toLocaleString()}
                                                </Tables.Data>
                                                <Tables.Data className='text-right'>
                                                    Rp.{post.saldo_display && post.saldo_display.kredit && post.saldo_display.kredit.toLocaleString()}
                                                </Tables.Data>
                                                <Tables.Data className='text-center'>
                                                    {/* {!post.is_approved && */}
                                                        <Tooltip tooltip={"Approve / Reject"} delay={500}>
                                                            <ButtonRipple
                                                                color={themeColor}
                                                                className='bg-primary text-white px-[5px] py-[2px] rounded'
                                                                onClick={() => handleDetail(post)}
                                                            ><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></ButtonRipple>
                                                        </Tooltip>
                                                    {/* } */}
                                                </Tables.Data>
                                            </Tables.Row>
                                        )
                                    })}
                                </Tables.Body>
                            </Tables>
                        </Fragment>
                    }
                </Card>
            }
        </div>
    )
}
export default ClientPage;