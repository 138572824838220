import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useContext, useRef } from "react";
import { TbX } from "react-icons/tb";
import { ButtonRipple } from "..";
import { ThemeContext } from "../../context/ThemeContext";

const Drawer = ({
	open,
	setOpen,
	width = 380,
	dismiss,
	title,
	description,
	children,
}) => {
	const { colorMode } = useContext(ThemeContext);
	const ref = useRef();
	useOnClickOutside(ref, () => {
		if (dismiss) {
			setOpen(false);
		}
	});

	return (
		<div
			ref={ref}
			className={`hidden md:block fixed top-0 z-50 bg-base-50/90 backdrop-blur-sm dark:bg-base-600/90 text-base-300 dark:text-base-200 h-screen shadow-2xl transition-[right] duration-500`}
			style={{
				width: width,
				right: open ? "0" : `-${width}px`,
			}}
		>
			<div className="sticky top-0 p-4 border-b border-neutral-200 dark:border-base-500 flex items-center justify-between">
				<div>
					<div className="leading-none">{title}</div>
					<div className="text-sm leading-none">{description}</div>
				</div>
				<ButtonRipple
					color={colorMode === "light" ? "#00000030" : "#ffffff30"}
					className="p-2 rounded-full transition-[background] hover:bg-neutral-200 dark:hover:bg-base-400"
					onClick={() => setOpen(false)}
				>
					<TbX />
				</ButtonRipple>
			</div>
			<div
				style={{
					height: `calc(100vh - 64px)`,
				}}
				className="overflow-y-auto custom-scroll"
			>
				{children}
			</div>
		</div>
	);
};

export default Drawer;
