import { useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import { DataContext } from "../../../context/DataContext";
import config from "../../../config";
import api from "../../../api";
import {  faTimesCircle } from "@fortawesome/free-solid-svg-icons";


export function useMasterRouter(props){
    const { 
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, bgColor
    } = useGlobalFunction();
    const { 
        routers, setRouters, fetchRouters, paginateRouters
    } = useContext(DataContext)
    const [view, setView] = useState('list');
    const [formData, setFormData] = useState({
        id : {
            name : 'id', 
            type : 'text',
            value : '',
            required : false, 
            show: false, 
            showError: false,
            label : 'ID',
            readOnly : true,
        },
        nama : {
            name : 'nama', 
            type : 'text',
            value : '',
            required : true, 
            show: true, 
            showError: false,
            label : 'Nama'
        },
        host : {
            name : 'host', 
            type : 'text',
            value : '',
            required : true, 
            show: true, 
            showError: false,
            label : 'Host (IP local / vpn)'
        },
        uname : {
            name : 'uname', 
            type : 'text',
            value : '',
            required : true, 
            show: true, 
            showError: false,
            label : 'Username'
        },
        pwd : {
            name : 'pwd', 
            type : 'password',
            inputType : 'password',
            value : '',
            required : true, 
            show: true, 
            showError: false,
            label : 'Password'
        },
        api_port : {
            name : 'api_port', 
            type : 'text',
            value : '',
            required : true, 
            show: true, 
            showError: false,
            label : 'Api Port'
        },
        ssh_port : {
            name : 'ssh_port', 
            type : 'text',
            value : '',
            required : true, 
            show: true, 
            showError: false,
            label : 'SSH Port'
        },
        dns_name : {
            name : 'dns_name', 
            type : 'text',
            value : '',
            required : true, 
            show: true, 
            showError: false,
            label : 'DNS Name'
        },
        deskripsi : {
            name : 'deskripsi', 
            type : 'text',
            value : '',
            required : true, 
            show: true, 
            showError: false,
            label : 'deskripsi'
        },
        os_ver : {
            name : 'os_ver', 
            type : 'text',
            value : '',
            required : true, 
            show: true, 
            showError: false,
            label : 'OS Version'
        },
    })
    const [isEdit, setIsEdit]= useState(false);

    useEffect(() => {
        fetchRouters({});
    },[fetchRouters])

    const handleSearch = () => {
        fetchRouters({
            q:routers.search, 
            offset:0, 
            limit: config.itemPerPage, 
            variable:null, 
            tipe_fetching:'update'
        })
        setRouters(routers => ({...routers,
            page : 1,
            offset : 0
        }))
    }

    const handleCreate = () => {
        setView('create');
    }


    const handleSubmit = async() => {
		setIsLoading(true);
		try{
			let obj = {...formData};
			let tmp = {}
			let countError = 0;

			Object.entries(obj).map(([index, post]) => {
				if (post.required && post.value === ''){
					post['showError'] = true;
					countError++;
				}else{
					post['showError'] = false;
					tmp[index] = post.value;
				}
				return true;
			})

			if (countError > 0){
				setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
				setTimeout(() => {
					setAlertValue(alertValue => ({...alertValue, show: false}))
				}, config.timeOutValue)
				setFormData(obj);
				setIsLoading(false);
				return;
			}
            

			let newForm = new FormData();
			newForm.append('formData', JSON.stringify(tmp));
			let res;
			if (isEdit){
				res = await api.put(`${config.endPoint.masterRouter}${formData.id.value}/`, newForm).then(res => res.data);
			}else{
				res = await api.post(`${config.endPoint.masterRouter}`, newForm).then(res => res.data);
			}
			
			if (res){
				setAlertValue(alertValue => ({...alertValue, show: true, text : "Data tersimpan", color : "success"}));
				setTimeout(() => {
					setAlertValue(alertValue => ({...alertValue, show: false}))
				}, config.timeOutValue);
				setView('list')
				setIsLoading(false)
				fetchRouters({
                    q:routers.search, 
                    offset:routers.offset, 
                    limit: config.itemPerPage, 
                    variable:null, 
                    tipe_fetching:'update'
                })
			}
		}catch(error){
			setIsLoading(false);
			const { response} = error;
			let msg = "Unable to fetch data"
			if (response && response.data && response.data.message){
				msg = response.data.message;
			}
			setAlertValue(alertValue => ({...alertValue, 
                show: true, 
                text : msg, 
                color : 'danger',
                icon: faTimesCircle
            }));
			setTimeout(() => {
				setAlertValue(alertValue => ({...alertValue, show: false}))
			}, config.timeOutValue)
		}
	}

    const handleCancel = () => {
        setView('list');
    }

    const handleEdit = (params) => {
        setView('create');
        setIsEdit(true);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : params.id},
            nama : {...formData.nama, value : params.nama},
            host : {...formData.host, value : params.host},
            uname : {...formData.uname, value : params.uname},
            pwd : {...formData.pwd, value : params.pwd},
            api_port : {...formData.api_port, value : params.api_port},
            ssh_port : {...formData.ssh_port, value : params.ssh_port},
            dns_name : {...formData.dns_name, value : params.dns_name},
            deskripsi : {...formData.deskripsi, value : params.deskripsi},
            os_ver : {...formData.os_ver, value : params.os_ver},
        }))
    }

    const handleDelete = (params) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            text : `Apakah anda yakin akan menghapus router ${params.nama} ?`,
            id : params.id
        }))
    }

    const handleSubmitDelete = async() => {
		setIsLoading(true);
		try{
			const res = await api.delete(`${config.endPoint.masterRouter}${modalValue.id}/`).then(res => res.data);
			if (res){
				handleCloseModal();
				setAlertValue(alertValue => ({...alertValue, show: true, text : 'Data Terhapus', color : 'success'}));
				setTimeout(() => {
					setAlertValue(alertValue => ({...alertValue, show: false}));
				}, config.timeOutValue);
                fetchRouters({
                    q:routers.search, 
                    offset:routers.offset, 
                    limit: config.itemPerPage, 
                    variable:null, 
                    tipe_fetching:'update'
                })
			}
			setIsLoading(false);
		}catch(error){
			setIsLoading(false);
			setAlertValue(alertValue => ({...alertValue, show: true, text : 'unable to delete data', color : 'danger'}));
			setTimeout(() => {
				setAlertValue(alertValue => ({...alertValue, show: false}));
			}, config.timeOutValue)
		}
	}

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, bgColor,
        routers, setRouters, fetchRouters, paginateRouters, 
        formData, setFormData, handleSearch, handleCreate, view, setIsEdit, handleSubmit, handleCancel, handleEdit, 
        handleDelete, handleSubmitDelete
    }
}